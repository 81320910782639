import { type ChangeEvent, useContext } from 'react';
import {
  BeautifulList,
  BeautifulListItem,
  Button,
  Checkbox,
  DeleteIcon,
  EditableText,
  ExpandableContent,
  Field,
  FieldStatus,
  IconButton,
  ImportantIcon,
  Popper,
  PopperBox,
  Select,
  snap,
  Table,
  TableCell,
  TableRow,
  units,
  useBeautifulList
} from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { type FormikErrors, type FormikTouched, useFormikContext } from 'formik';
import { DragDropContext, Draggable, Droppable, type DropResult } from 'react-beautiful-dnd';
import { hasValue } from '@m/magic-typescript';
import { type IChallenge, type MixedTypes } from '../../../common/interfaces';
import { ChallengeIdeaDescriptionType, ChallengeQuestionType, SelectorType } from '../../../common/enums';
import { type Item } from '../../../common/components';
import { useFieldError, usePortalledDraggable } from '../../../hooks';
import { type ChallengeQuestion, type Milestone, type Phase } from '../../../common/core-api';
import { CommunityContext } from '../../../context';
import { TranslatableInput } from './TranslatableInput';

export interface CustomizeFormProps {
  readonly type: SelectorType;
  readonly currentMilestoneIndex?: number;
  readonly milestones?: Milestone[];
  readonly milestoneErrorsMeta?: {
    errors: FormikErrors<IChallenge | Milestone>;
    touched: FormikTouched<IChallenge | Milestone>;
  };
}

const StyledCheckbox = styled(Checkbox)`
  padding-bottom: ${units(1.3)};
`;

const StyledImportantIcon = styled(ImportantIcon)`
  margin-left: ${units(1)};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin: ${units(2)} 0;
  & > * {
    &:not(:last-child) {
      margin-right: ${units(1)};
    }
  }
`;

const StyledField = styled(Field)`
  max-width: 100%;
  span:nth-child(2) {
    opacity: 0;
  }
`;

const FieldStatusWrapper = styled.div`
  white-space: pre-line;
`;

const StyledTable = styled(Table)<{ indented?: boolean; type: SelectorType }>`
  table-layout: fixed;
  input {
    min-width: 0;
  }
  tr {
    th,
    td {
      vertical-align: unset;
      &:first-child {
        ${({ indented, type }) => {
          if (type === SelectorType.PHASE) {
            return css`
              width: ${snap(50)};
            `;
          }

          if (type === SelectorType.MILESTONE) {
            return indented
              ? css`
                  width: ${snap(86)};
                `
              : css`
                  width: ${snap(70)};
                `;
          }

          return indented
            ? css`
                width: ${snap(160)};
              `
            : css`
                width: ${snap(144)};
              `;
        }}
      }
      &:nth-child(2) {
        ${({ type }) => {
          if (type === SelectorType.MILESTONE) {
            return css`
              width: ${snap(280)};
              max-width: ${snap(280)};
            `;
          }

          if (type === SelectorType.PHASE) {
            return css`
              width: ${snap(200)};
              max-width: ${snap(200)};
            `;
          }

          return css`
            width: ${snap(220)};
            max-width: ${snap(220)};
          `;
        }}
      }
      &:nth-child(3) {
        ${({ type }) => {
          if (type === SelectorType.MILESTONE) {
            return css`
              width: ${snap(240)};
              max-width: ${snap(240)};
            `;
          }

          return css`
            width: ${snap(220)};
            max-width: ${snap(220)};
          `;
        }}
      }
      &:nth-child(4) {
        width: ${snap(110)};
        max-width: ${snap(110)};
      }
      &:nth-child(n + 5) {
        ${({ type }) => {
          if (type === SelectorType.MILESTONE) {
            return css`
              width: ${snap(75)};
            `;
          }

          return css`
            width: ${snap(60)};
          `;
        }}
      }
    }
  }
`;

interface QuestionRowProps {
  type: SelectorType;
  inPhase?: boolean;
  inMilestone?: boolean;
  showInMilestone?: boolean;
}
const QuestionRow = styled(TableRow)<QuestionRowProps>`
  // set display of each question here since questions have
  // all of the questions for challenge, phase, and milestone
  ${({ type, inPhase, inMilestone, showInMilestone }) => {
    if (
      (type === SelectorType.CHALLENGE && (inPhase || inMilestone)) ||
      (type === SelectorType.PHASE && !inPhase) ||
      (type === SelectorType.MILESTONE && (!inMilestone || !showInMilestone))
    ) {
      return css`
        display: none;
      `;
    }

    return '';
  }}
`;

export const CustomizeForm = ({ type, milestones, currentMilestoneIndex }: CustomizeFormProps) => {
  const intl = useIntl();
  const portalledDraggable = usePortalledDraggable();
  const community = useContext(CommunityContext);

  const {
    values: challenge,
    setFieldValue,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldTouched
  } = useFormikContext<MixedTypes>();
  const [fieldError] = useFieldError();
  const { questions } = challenge;

  const messages = {
    na: <FormattedMessage id="na" defaultMessage="N/A" />,
    text: <FormattedMessage id="text" defaultMessage="Text" />
  };

  const editableTextStringMap = {
    editIconTitle: intl.formatMessage({
      id: 'editIconTitle',
      defaultMessage: 'Click to edit this text'
    }),
    confirmIconTitle: intl.formatMessage({
      id: 'confirmIconTitle',
      defaultMessage: 'Confirm'
    }),
    cancelIconTitle: intl.formatMessage({
      id: 'cancelIconTitle',
      defaultMessage: 'Cancel'
    })
  };

  const challengeTypeSelections = [
    {
      label: intl.formatMessage({ id: 'text', defaultMessage: 'Text' }),
      value: ChallengeQuestionType.TEXT
    },
    {
      label: intl.formatMessage({ id: 'option', defaultMessage: 'Option' }),
      value: ChallengeQuestionType.OPTION
    },
    ...(community.options?.livingLens?.enabled
      ? [
          {
            label: intl.formatMessage({ id: 'video', defaultMessage: 'Video' }),
            value: ChallengeQuestionType.VIDEO
          },
          {
            label: intl.formatMessage({ id: 'audio', defaultMessage: 'Audio' }),
            value: ChallengeQuestionType.AUDIO
          }
        ]
      : [])
  ].filter(hasValue);

  const ideaDescriptionTypeSelection = [
    {
      label: intl.formatMessage({ id: 'text', defaultMessage: 'Text' }),
      value: ChallengeIdeaDescriptionType.TEXT
    },
    ...(community.options?.livingLens?.enabled
      ? [
          {
            label: intl.formatMessage({ id: 'video', defaultMessage: 'Video' }),
            value: ChallengeIdeaDescriptionType.VIDEO
          },
          {
            label: intl.formatMessage({ id: 'audio', defaultMessage: 'Audio' }),
            value: ChallengeIdeaDescriptionType.AUDIO
          }
        ]
      : [])
  ].filter(hasValue);

  const getMilestoneIndex = (milestoneId?: string | null) =>
    milestones?.findIndex((milestone) => milestone.id === milestoneId) || 0;

  const getMilestoneQuestionIndex = (milestone: Milestone, questionId: string) =>
    milestone.questions?.findIndex((question) => question.id === questionId) || 0;

  const handleQuestionChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: number | string; type?: string; checked?: boolean } }
  ) => {
    const { name, value, type, checked } = event.target;

    return setFieldValue(name, type === 'checkbox' ? checked : value);
  };

  const handleIdeaDescriptionChange = ({ value }: { value: string }) =>
    setFieldValue('config.ideaDescriptionType', value);

  const handleAddQuestion = () => {
    const beforeQuestion = questions && questions.length > 0 && questions[questions.length - 1];

    const newQuestion = {
      // Temporary ID to be replaced on save
      id: Date.now().toString(),
      inCreation: true,
      name: '',
      required: false,
      hidden: false,
      order: beforeQuestion ? Number(beforeQuestion.order) + 1 : 1,
      type: ChallengeQuestionType.TEXT,
      characterLimit: '',
      inPhase: type === SelectorType.PHASE,
      milestoneId: type === SelectorType.MILESTONE ? challenge.id : '0',
      options: []
    };

    return setFieldValue('questions', [...(challenge.questions || []), newQuestion]);
  };

  const handleDelete = (question: ChallengeQuestion) =>
    setFieldValue(
      'questions',
      (challenge.questions || []).filter((existingQuestion) => existingQuestion.id !== question.id)
    );

  const handleAddOption = (question: ChallengeQuestion) => {
    const newOption = {
      id: Date.now().toString(),
      text: ''
    };
    const milestoneQuestionIndex = getMilestoneQuestionIndex(challenge, question.id);

    return setFieldValue(`questions[${milestoneQuestionIndex}].options`, [
      ...((challenge.questions && challenge.questions[milestoneQuestionIndex].options) || []),
      newOption
    ]);
  };

  const handleDeleteOption = (optionIndex: number, question: ChallengeQuestion) => {
    const milestoneQuestionIndex = getMilestoneQuestionIndex(challenge, question.id);
    const options = (challenge.questions && challenge.questions[milestoneQuestionIndex].options) || [];

    return setFieldValue(
      `questions[${milestoneQuestionIndex}].options`,
      options.filter((_, index) => index !== optionIndex)
    );
  };

  const handleUseQuestion = (
    { target: { name, value, checked } }: ChangeEvent<HTMLInputElement>,
    question: ChallengeQuestion
  ) => {
    if (type === SelectorType.PHASE) {
      if (checked) {
        return setFieldValue('questions', [...(questions || []), question]);
      }

      const newQuestionsValue = (questions || [])?.filter((q) => q.id !== question.id);
      return setFieldValue('questions', newQuestionsValue);
    }

    if (type === SelectorType.MILESTONE) {
      handleQuestionChange({ target: { name, value, type: 'checkbox', checked: !question.hidden } });
    }
  };

  const reorder = (items: Item[], result: DropResult) => {
    const startIndex = result.source.index;
    const endIndex = result.destination?.index || 0;

    const orderedList = [...items];
    const [removed] = orderedList.splice(startIndex, 1);
    orderedList.splice(endIndex, 0, removed);

    const orderedQuestions = [...(questions || [])];
    const [removedQuestion] = orderedQuestions.splice(startIndex, 1);
    orderedQuestions.splice(endIndex, 0, removedQuestion);

    const newQuestionsValue = orderedQuestions
      .sort((a, b) => getMilestoneIndex(a.milestoneId) - getMilestoneIndex(b.milestoneId))
      .map((question: ChallengeQuestion, index: number) => ({
        ...question,
        order: index + 1
      }));

    setFieldValue('questions', newQuestionsValue);

    return orderedList;
  };

  const handleETonChange = (
    e: ChangeEvent<HTMLInputElement>,
    callback?: (e: ChangeEvent<HTMLInputElement>) => void
  ) => {
    if (callback) {
      callback(e);
    } else {
      handleChange(e);
    }

    setFieldTouched(e.target.name, true);
  };

  const { onDragEnd } = useBeautifulList({ defaultCollection: [], reorder });

  return (
    // withoutScroller removes dependency on complex overflow scroller since we are not using it
    // anywhere else in the app and basic css scrolling is good enough
    <>
      <StyledTable indented withoutScroller type={type}>
        <thead>
          <TableRow>
            <TableCell isHeader>
              {(type === SelectorType.PHASE || type === SelectorType.MILESTONE) && (
                <FormattedMessage id="use" defaultMessage="Use" />
              )}
              {type === SelectorType.CHALLENGE && <FormattedMessage id="formField" defaultMessage="Form Field" />}
            </TableCell>
            <TableCell isHeader>
              {(type === SelectorType.PHASE || type === SelectorType.MILESTONE) && (
                <FormattedMessage id="title" defaultMessage="Title" />
              )}
              {type === SelectorType.CHALLENGE && (
                <FormattedMessage id="onscreenFieldTitle " defaultMessage="Onscreen Field Title" />
              )}
            </TableCell>
            <TableCell isHeader>
              <FormattedMessage id="type" defaultMessage="Type" />
            </TableCell>
            <TableCell isHeader>
              <FormattedMessage id="characterLimit " defaultMessage="Character Limit" />
            </TableCell>
            <TableCell isHeader>
              <FormattedMessage id="required" defaultMessage="Required" />
            </TableCell>
            {type === SelectorType.CHALLENGE && (
              <TableCell isHeader>
                <FormattedMessage id="hide" defaultMessage="Hide" />
              </TableCell>
            )}
            <TableCell isHeader>
              <FormattedMessage id="delete" defaultMessage="Delete" />
            </TableCell>
          </TableRow>
        </thead>
        <tbody>
          {/* Select a theme */}
          {type === SelectorType.CHALLENGE && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="selectTheme" defaultMessage="Select a theme" />
                <Popper
                  placement="top"
                  mode="tooltip"
                  overlay={
                    <PopperBox hasArrow colorScheme="alchemyDark">
                      <FormattedMessage
                        id="themeSelectHint"
                        defaultMessage="This field is only visible when you have themes in your challenge"
                      />
                    </PopperBox>
                  }
                  trigger={
                    <StyledImportantIcon
                      aria-label={intl.formatMessage({ id: 'importantTrigger', defaultMessage: 'Important' })}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <StyledField
                  status={fieldError('headings.themeSelectHeading')}
                  data-testid="customHeadings.themeSelectHeading"
                  input={
                    <TranslatableInput
                      type="Challenge"
                      field="customHeadings.themeSelectHeading"
                      translateId={challenge.id}
                    >
                      <EditableText
                        inputProps={{
                          // Alchemy EditableText inputProps only supports onChange
                          name: 'headings.themeSelectHeading',
                          onBlur: handleBlur,
                          onChange: handleETonChange
                        }}
                        value={challenge.headings.themeSelectHeading}
                        stringMap={editableTextStringMap}
                        shouldHideActions
                        placeholder={intl.formatMessage({
                          id: 'selectTheme',
                          defaultMessage: 'Select a theme'
                        })}
                      />
                    </TranslatableInput>
                  }
                />
              </TableCell>
              <TableCell>{messages.text}</TableCell>
              <TableCell>{messages.na}</TableCell>
              <TableCell>
                <StyledCheckbox
                  label=""
                  checked={challenge.config.themeRequired}
                  name="config.themeRequired"
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell>{messages.na}</TableCell>
              <TableCell>{messages.na}</TableCell>
            </TableRow>
          )}

          {/* Enter title */}
          {type === SelectorType.CHALLENGE && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="enterTitleText" defaultMessage="'Enter title' text" />
              </TableCell>
              <TableCell>
                <StyledField
                  status={fieldError('headings.ideaTitleHeading')}
                  data-testid="customHeadings.ideaTitleHeading"
                  input={
                    <TranslatableInput
                      type="Challenge"
                      field="customHeadings.ideaTitleHeading"
                      translateId={challenge.id}
                    >
                      <EditableText
                        inputProps={{
                          name: 'headings.ideaTitleHeading',
                          onBlur: handleBlur,
                          onChange: handleETonChange
                        }}
                        value={challenge.headings.ideaTitleHeading}
                        stringMap={editableTextStringMap}
                        shouldHideActions
                        placeholder={intl.formatMessage({
                          id: 'enterTitleText',
                          defaultMessage: "'Enter title' text"
                        })}
                      />
                    </TranslatableInput>
                  }
                />
                <ExpandableContent
                  hasArrow={false}
                  hasDivider={false}
                  label={intl.formatMessage({ id: 'editDescription', defaultMessage: 'Edit description' })}
                >
                  <StyledField
                    status={fieldError('headings.ideaTitleInfoHeading')}
                    input={
                      <TranslatableInput
                        type="Challenge"
                        field="customHeadings.ideaTitleInfoHeading"
                        translateId={challenge.id}
                      >
                        <EditableText
                          inputProps={{
                            name: 'headings.ideaTitleInfoHeading',
                            onBlur: handleBlur,
                            onChange: handleETonChange
                          }}
                          value={challenge.headings.ideaTitleInfoHeading}
                          stringMap={editableTextStringMap}
                          shouldHideActions
                          placeholder={intl.formatMessage({
                            id: 'editDescription',
                            defaultMessage: 'Edit description'
                          })}
                        />
                      </TranslatableInput>
                    }
                  />
                </ExpandableContent>
              </TableCell>
              <TableCell>{messages.text}</TableCell>
              <TableCell>{messages.na}</TableCell>
              <TableCell>
                <Popper
                  placement="top"
                  mode="tooltip"
                  overlay={
                    <PopperBox hasArrow colorScheme="alchemyDark">
                      <FormattedMessage id="enterTitleTextHint" defaultMessage="A title is always required" />
                    </PopperBox>
                  }
                  trigger={<StyledCheckbox label="" disabled checked />}
                />
              </TableCell>
              <TableCell>{messages.na}</TableCell>
              <TableCell>{messages.na}</TableCell>
            </TableRow>
          )}

          {/* Describe Idea */}
          {type === SelectorType.CHALLENGE && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="describeIdeaText" defaultMessage="'Describe idea' text" />
              </TableCell>
              <TableCell>
                <StyledField
                  status={fieldError('headings.ideaDescriptionHeading')}
                  input={
                    <TranslatableInput
                      type="Challenge"
                      field="customHeadings.ideaDescriptionHeading"
                      translateId={challenge.id}
                    >
                      <EditableText
                        inputProps={{
                          name: 'headings.ideaDescriptionHeading',
                          onBlur: handleBlur,
                          onChange: handleETonChange
                        }}
                        value={challenge.headings.ideaDescriptionHeading}
                        stringMap={editableTextStringMap}
                        shouldHideActions
                        placeholder={intl.formatMessage({
                          id: 'describeIdeaText',
                          defaultMessage: "'Describe idea' text"
                        })}
                      />
                    </TranslatableInput>
                  }
                />
                <ExpandableContent
                  hasArrow={false}
                  hasDivider={false}
                  label={intl.formatMessage({ id: 'editDescription', defaultMessage: 'Edit description' })}
                >
                  <StyledField
                    status={fieldError('headings.ideaDescriptionInfoHeading')}
                    input={
                      <TranslatableInput
                        type="Challenge"
                        field="customHeadings.ideaDescriptionInfoHeading"
                        translateId={challenge.id}
                      >
                        <EditableText
                          inputProps={{
                            name: 'headings.ideaDescriptionInfoHeading',
                            onBlur: handleBlur,
                            onChange: handleETonChange
                          }}
                          value={challenge.headings.ideaDescriptionInfoHeading}
                          stringMap={editableTextStringMap}
                          shouldHideActions
                          placeholder={intl.formatMessage({
                            id: 'editDescription',
                            defaultMessage: 'Edit description'
                          })}
                        />
                      </TranslatableInput>
                    }
                  />
                </ExpandableContent>
              </TableCell>

              {!challenge.config.ideaDescriptionType && Boolean(challenge.ideas?.length) ? (
                <TableCell>{messages.text}</TableCell>
              ) : (
                <TableCell>
                  <Select
                    disabled={Boolean(challenge.ideas?.length)}
                    onChange={(nextValue) => handleIdeaDescriptionChange(nextValue)}
                    options={ideaDescriptionTypeSelection}
                    value={challenge.config.ideaDescriptionType || ChallengeQuestionType.TEXT}
                  />
                </TableCell>
              )}

              {challenge.config.ideaDescriptionType === 'audio' || challenge.config.ideaDescriptionType === 'video' ? (
                <TableCell>{messages.na}</TableCell>
              ) : (
                <TableCell>
                  <StyledField
                    status={fieldError('config.ideaDescriptionCharacterLimit')}
                    data-testid="ideaDescriptionCharacterLimit"
                    input={
                      <EditableText
                        inputProps={{
                          name: 'config.ideaDescriptionCharacterLimit',
                          onBlur: handleBlur,
                          onChange: handleETonChange
                        }}
                        value={
                          challenge.config.ideaDescriptionCharacterLimit
                            ? String(challenge.config.ideaDescriptionCharacterLimit)
                            : ''
                        }
                        stringMap={editableTextStringMap}
                        shouldHideActions
                        placeholder={intl.formatMessage({
                          id: 'limit',
                          defaultMessage: 'Limit'
                        })}
                      />
                    }
                  />
                </TableCell>
              )}

              <TableCell>
                <Popper
                  placement="top"
                  mode="tooltip"
                  overlay={
                    <PopperBox hasArrow colorScheme="alchemyDark">
                      <FormattedMessage id="describeIdeaTextHint" defaultMessage="A description is always required" />
                    </PopperBox>
                  }
                  trigger={<StyledCheckbox label="" disabled checked />}
                />
              </TableCell>
              <TableCell>{messages.na}</TableCell>
              <TableCell>{messages.na}</TableCell>
            </TableRow>
          )}
        </tbody>
      </StyledTable>

      {questions && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <BeautifulList {...provided.droppableProps} ref={provided.innerRef}>
                {questions.map((question, index) => {
                  const showInMilestone =
                    type === SelectorType.MILESTONE && questions?.map((q) => q && q.id).includes(question.id);
                  const inMilestone = Number(question.milestoneId) > 0 || showInMilestone;

                  const usedInPhase = type === SelectorType.PHASE && questions?.map((q) => q.id).includes(question.id);
                  const usedInMilestone = type === SelectorType.MILESTONE && inMilestone && !question.hidden;
                  const used = usedInPhase || usedInMilestone;

                  const questionPath = `questions[${index}]`;
                  const questionErrors: {
                    errors: FormikErrors<IChallenge | Milestone | Phase>;
                    touched: FormikTouched<IChallenge | Milestone | Phase>;
                  } = { errors, touched };
                  // console.log(questionErrors);

                  return (
                    <Draggable
                      key={question.id}
                      draggableId={question.id}
                      index={index}
                      isDragDisabled={type === SelectorType.MILESTONE && milestones && !currentMilestoneIndex}
                    >
                      {portalledDraggable((provided, snapshot) => (
                        <BeautifulListItem draggableProvided={provided} draggableStateSnapshot={snapshot}>
                          <StyledTable withoutScroller type={type}>
                            <tbody>
                              <QuestionRow
                                key={question.id}
                                type={type}
                                inPhase={question.inPhase || undefined}
                                inMilestone={inMilestone}
                                showInMilestone={showInMilestone}
                              >
                                <TableCell>
                                  {(type === SelectorType.PHASE || type === SelectorType.MILESTONE) && (
                                    <StyledCheckbox
                                      label=""
                                      checked={used}
                                      name={`${questionPath}.hidden`}
                                      data-testid="useQuestion"
                                      onChange={(event) => handleUseQuestion(event, question)}
                                    />
                                  )}
                                  {type === SelectorType.CHALLENGE && (
                                    <FormattedMessage id="additionalQuestion" defaultMessage="Additional question:" />
                                  )}
                                </TableCell>
                                <TableCell>
                                  <StyledField
                                    status={fieldError(`${questionPath}.name`, questionErrors)}
                                    data-testid="question.title"
                                    input={
                                      <TranslatableInput
                                        type="ChallengeQuestion"
                                        field="name"
                                        translateId={question.id}
                                      >
                                        <EditableText
                                          inputProps={{
                                            name: `${questionPath}.name`,
                                            onBlur: handleBlur,
                                            onChange: (e) => handleETonChange(e, handleQuestionChange)
                                          }}
                                          value={question.name || undefined}
                                          stringMap={editableTextStringMap}
                                          shouldHideActions
                                          placeholder={intl.formatMessage({
                                            id: 'questionNamePlaceholder',
                                            defaultMessage: 'Question title'
                                          })}
                                        />
                                      </TranslatableInput>
                                    }
                                  />
                                  <ExpandableContent
                                    hasArrow={false}
                                    hasDivider={false}
                                    data-testid="question.description-button"
                                    label={
                                      question.description
                                        ? intl.formatMessage({
                                            id: 'editDescription',
                                            defaultMessage: 'Edit description'
                                          })
                                        : intl.formatMessage({
                                            id: 'addDescription',
                                            defaultMessage: 'Add description'
                                          })
                                    }
                                  >
                                    <StyledField
                                      data-testid="question.description"
                                      input={
                                        <TranslatableInput
                                          type="ChallengeQuestion"
                                          field="description"
                                          translateId={question.id}
                                        >
                                          <EditableText
                                            inputProps={{
                                              name: `${questionPath}.description`,
                                              onBlur: handleBlur,
                                              onChange: (e) => handleETonChange(e, handleQuestionChange)
                                            }}
                                            value={question.description || undefined}
                                            stringMap={editableTextStringMap}
                                            shouldHideActions
                                            placeholder={intl.formatMessage({
                                              id: 'questionDescriptionPlaceholder',
                                              defaultMessage: 'Question description'
                                            })}
                                          />
                                        </TranslatableInput>
                                      }
                                    />
                                  </ExpandableContent>
                                </TableCell>
                                <TableCell>
                                  <Select
                                    data-testid="question.type"
                                    disabled={Boolean(challenge.ideas?.length) && !question.inCreation}
                                    onChange={({ value }) =>
                                      handleQuestionChange({ target: { name: `${questionPath}.type`, value } })
                                    }
                                    options={challengeTypeSelections}
                                    value={question.type}
                                  />
                                  {question.type === ChallengeQuestionType.OPTION && (
                                    <>
                                      {question.options?.map((option, optionIndex) => (
                                        <FlexRow key={option.id}>
                                          <StyledField
                                            status={fieldError(
                                              `${questionPath}.options[${optionIndex}].text`,
                                              questionErrors
                                            )}
                                            input={
                                              <TranslatableInput
                                                type="ChallengeQuestionOption"
                                                field="text"
                                                translateId={option.id}
                                              >
                                                <EditableText
                                                  inputProps={{
                                                    name: `${questionPath}.options[${optionIndex}].text`,
                                                    onBlur: handleBlur,
                                                    onChange: (e) => handleETonChange(e, handleQuestionChange)
                                                  }}
                                                  value={option.text || undefined}
                                                  stringMap={editableTextStringMap}
                                                  shouldHideActions
                                                  placeholder={intl.formatMessage({
                                                    id: 'questionOptionPlaceholder',
                                                    defaultMessage: 'Option'
                                                  })}
                                                />
                                              </TranslatableInput>
                                            }
                                          />
                                          <IconButton
                                            data-testid={`deleteOption-${option.id}`}
                                            icon={
                                              <DeleteIcon
                                                aria-label={intl.formatMessage({
                                                  id: 'deleteIcon',
                                                  defaultMessage: 'Delete icon'
                                                })}
                                              />
                                            }
                                            tooltipContents={intl.formatMessage({
                                              id: 'deleteOption',
                                              defaultMessage: 'Delete option'
                                            })}
                                            onClick={() => handleDeleteOption(optionIndex, question)}
                                          />
                                        </FlexRow>
                                      ))}
                                      <Button
                                        priority="tertiary"
                                        size="extraSmall"
                                        onClick={() => handleAddOption(question)}
                                      >
                                        <FormattedMessage id="addOption" defaultMessage="Add new option" />
                                      </Button>
                                      <FieldStatusWrapper>
                                        <FieldStatus
                                          message={fieldError(`${questionPath}.options`, questionErrors)?.message}
                                          level="error"
                                        />
                                      </FieldStatusWrapper>
                                    </>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {question.type === ChallengeQuestionType.TEXT ? (
                                    <StyledField
                                      data-testid="question.characterLimit"
                                      status={fieldError(`${questionPath}.characterLimit`, questionErrors)}
                                      input={
                                        <EditableText
                                          inputProps={{
                                            name: `${questionPath}.characterLimit`,
                                            onBlur: handleBlur,
                                            onChange: (e) => handleETonChange(e, handleQuestionChange)
                                          }}
                                          value={question.characterLimit ? String(question.characterLimit) : ''}
                                          stringMap={editableTextStringMap}
                                          shouldHideActions
                                          placeholder={intl.formatMessage({
                                            id: 'limit',
                                            defaultMessage: 'Limit'
                                          })}
                                        />
                                      }
                                    />
                                  ) : (
                                    messages.na
                                  )}
                                </TableCell>
                                <TableCell>
                                  <StyledCheckbox
                                    label=""
                                    checked={question.required || undefined}
                                    name={`${questionPath}.required`}
                                    onChange={handleQuestionChange}
                                    data-testid="question.required"
                                  />
                                </TableCell>
                                {type === SelectorType.CHALLENGE && (
                                  <TableCell>
                                    <StyledCheckbox
                                      label=""
                                      checked={question.hidden || undefined}
                                      name={`${questionPath}.hidden`}
                                      onChange={handleQuestionChange}
                                      data-testid="question.hidden"
                                    />
                                  </TableCell>
                                )}
                                <TableCell>
                                  <IconButton
                                    icon={
                                      <DeleteIcon
                                        aria-label={intl.formatMessage({
                                          id: 'deleteIcon',
                                          defaultMessage: 'Delete icon'
                                        })}
                                      />
                                    }
                                    tooltipContents={intl.formatMessage({
                                      id: 'deleteQuestion',
                                      defaultMessage: 'Delete question'
                                    })}
                                    onClick={() => handleDelete(question)}
                                  />
                                </TableCell>
                              </QuestionRow>
                            </tbody>
                          </StyledTable>
                        </BeautifulListItem>
                      ))}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </BeautifulList>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <StyledTable indented withoutScroller type={type}>
        <tbody>
          <TableRow>
            <TableCell colSpan={7}>
              <Button priority="secondary" onClick={handleAddQuestion} data-testid="addNewQuestion">
                <FormattedMessage id="addNewQuestion" defaultMessage="Add new question" />
              </Button>
            </TableCell>
          </TableRow>
          {/* Upload files */}
          {type === SelectorType.CHALLENGE && (
            <TableRow>
              <TableCell>
                <FormattedMessage id="uploadFilesText" defaultMessage="'Upload files' text" />
              </TableCell>
              <TableCell>
                <StyledField
                  status={fieldError('headings.ideaFilesHeading')}
                  data-testid="customHeadings.ideaFilesHeading"
                  input={
                    <TranslatableInput
                      type="Challenge"
                      field="customHeadings.ideaFilesHeading"
                      translateId={challenge.id}
                    >
                      <EditableText
                        inputProps={{
                          name: 'headings.ideaFilesHeading',
                          onBlur: handleBlur,
                          onChange: handleETonChange
                        }}
                        value={challenge.headings.ideaFilesHeading}
                        stringMap={editableTextStringMap}
                        shouldHideActions
                        placeholder={intl.formatMessage({
                          id: 'uploadFilesText',
                          defaultMessage: "'Upload files' text"
                        })}
                      />
                    </TranslatableInput>
                  }
                />
              </TableCell>
              <TableCell>{messages.na}</TableCell>
              <TableCell>{messages.na}</TableCell>
              <TableCell>{messages.na}</TableCell>
              <TableCell>
                <StyledCheckbox
                  label=""
                  checked={challenge.config.hideUploadFiles || undefined}
                  name="config.hideUploadFiles"
                  onChange={handleChange}
                />
              </TableCell>
              <TableCell>{messages.na}</TableCell>
            </TableRow>
          )}
        </tbody>
      </StyledTable>
    </>
  );
};
