import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, ErrorMessage, LoadingIndicator, Select } from '@m/alchemy-ui';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useIntl } from 'react-intl';
import Funnel from 'highcharts/modules/funnel';
import Exporting from 'highcharts/modules/exporting';
import {
  type ChallengesQuery,
  type ChallengeWithFiltersQuery,
  type CoauthorIdeasQuery,
  type FilteredUsersQuery,
  GET_CHALLENGE_WITH_FILTERS,
  GET_CHALLENGES,
  GET_COAUTHOR_IDEAS,
  GET_COUNTRIES,
  GET_IDEAS_AND_COUNT,
  GET_USERFIELDS_FILTERED_USERIDS,
  type GetCountriesQuery,
  type GetIdeasAndCountQuery
} from '../../graphql/queries';
import { useUserFields } from '../../hooks';
import { CommunityContext } from '../../context';
import { ChallengeType } from '../../common/enums';
import { ChallengePublicationStatus } from '../../common/core-api';
import { Grid, GridCol, GridHeader, GridRow, Pagination } from './Analytics.styled';
import * as Styled from './components/SearchFilters.styled';
import { downloadIcon, ideasByPhaseChartOptions } from './HighchartsData';
import { CustomFilters } from './CustomFilters';
import { type UserFieldType } from './types';
import { IdeasTable } from './components';
import { AlchemySortDirectionType, ChallengeSelectionType, CoreApiSortDirectionType } from './enums';
import { EXPORT_TO_CSV_ENDPOINT, IDEAS_DEFAULT_SORT, IDEAS_PER_PAGE } from './constants';

Exporting(Highcharts);
Funnel(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.download = downloadIcon;

export const IdeasByPhaseStats = () => {
  const intl = useIntl();
  const community = useContext(CommunityContext);
  const { data: communityUserFields } = useUserFields();

  const defaultUserFieldOptions: UserFieldType = {};
  const [userFieldOptions, setUserFieldOptions] = useState(defaultUserFieldOptions);

  const [countries, setCountries] = useState<string[]>([]);
  const [challenge, setChallenge] = useState<string>(ChallengeSelectionType.SELECT);
  const [sort, setSort] = useState<string[][]>([IDEAS_DEFAULT_SORT]);
  const [offset, setOffset] = useState<number>(0);

  const [chartData, setChartData] = useState<ChallengeWithFiltersQuery>();

  const [skip, setSkip] = useState(true);

  const { data: countriesList } = useQuery<GetCountriesQuery>(GET_COUNTRIES, {
    variables: { id: community.id },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (countriesList?.community) {
      countriesList.community && setCountries(countriesList.community.countries as string[]);
    }
  }, [countriesList]);

  const [phase, setPhase] = useState<number>();

  const userFields = JSON.stringify(userFieldOptions);

  const [getChallenge, { data: challengeData }] = useLazyQuery<ChallengeWithFiltersQuery>(GET_CHALLENGE_WITH_FILTERS, {
    variables: { id: challenge, filters: { userFields } }
  });

  useEffect(() => {
    if (challengeData) {
      setChartData(challengeData);
    }
  }, [setChartData, challengeData]);

  const [getFilteredUserIds, { data: customFiltersUserIds }] = useLazyQuery<FilteredUsersQuery>(
    GET_USERFIELDS_FILTERED_USERIDS,
    {
      variables: { filters: { userFields } },
      onCompleted: (response) => {
        getCoauthorIdeaIds({
          variables: {
            filters: { userFields },
            customFiltersUserIds: response?.filteredUsers || []
          }
        });
      }
    }
  );

  const [getCoauthorIdeaIds, { data: coauthorIdeaIds }] = useLazyQuery<CoauthorIdeasQuery>(GET_COAUTHOR_IDEAS, {
    variables: { customFiltersUserIds, filters: { userFields } }
  });

  const challengesVariables = {
    order: ['title'],
    types: [ChallengeType.FUNNEL],
    publicationStatus: ChallengePublicationStatus.PUBLISHED
  };
  const { loading: loadingFunnelChallenges, data: funnelChallengeList } = useQuery<ChallengesQuery>(GET_CHALLENGES, {
    variables: challengesVariables
  });

  const [ideasQueryVariables, setIdeasQueryVariables] = useState({
    challengeSelection: challenge,
    phaseSelection: phase,
    filters: { userFields },
    order: sort,
    offset: offset * 10
  });

  const { data: ideasData } = useQuery<GetIdeasAndCountQuery>(GET_IDEAS_AND_COUNT, {
    variables: ideasQueryVariables,
    skip
  });

  useEffect(() => {
    if (!phase) {
      return;
    }

    if (!skip) {
      setIdeasQueryVariables({
        challengeSelection: challenge,
        phaseSelection: phase,
        filters: { userFields },
        order: sort,
        offset: offset * 10
      });
    }
  }, [challenge, phase, userFields, setIdeasQueryVariables, skip, sort, offset]);

  const lastUserFieldOptions = useRef<UserFieldType>(userFieldOptions);

  useEffect(() => {
    lastUserFieldOptions.current = userFieldOptions;
  }, [userFieldOptions]);

  const baseChartOptions = useMemo(() => ideasByPhaseChartOptions(intl), [intl]);

  const highchartsOptions = useMemo(() => {
    const phases = chartData?.challenge?.phases?.map((phase) => {
      const name = phase.name || intl.formatMessage({ id: 'unnamedPhase', defaultMessage: 'Unnamed phase' });

      return {
        x: Number(phase.id),
        y: chartData?.challenge?.phases?.length,
        name,
        challengeId: chartData?.challenge?.id,
        ideas: phase?.ideas?.length,
        startDate: phase.startDate,
        endDate: phase.endDate
      };
    });

    return {
      ...baseChartOptions,

      chart: {
        type: 'funnel'
      },
      series: [
        {
          name: intl.formatMessage({ id: 'phasesInThisChallenge', defaultMessage: 'Phases in this challenge' }),
          data: phases
        }
      ],
      title: {
        text: '',
        x: -50
      },
      plotOptions: {
        ...baseChartOptions.plotOptions,
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click(event: Highcharts.PointClickEventObject) {
                const phaseId = event.point.x;

                // Toggle the selection status of a point
                event.point.select();

                setPhase(phaseId);
                setSkip(false);
                setOffset(0);
              }
            }
          }
        }
      }
    };
  }, [baseChartOptions, setPhase, intl, chartData, setSkip, setOffset]);

  const sortIdeas = (column: string) => {
    const [sortColumn, sortDirection] = sort[0];
    const newOrder = [
      [
        column,
        column === sortColumn && sortDirection === CoreApiSortDirectionType.ASC
          ? CoreApiSortDirectionType.DESC
          : CoreApiSortDirectionType.ASC
      ]
    ];
    setSort(newOrder);
    setIdeasQueryVariables({
      ...ideasQueryVariables,
      order: newOrder
    });
  };

  const getColumnSortDirection = (column: string) => {
    const [sortColumn, sortDirection] = sort[0];
    return sortColumn === column
      ? sortDirection === CoreApiSortDirectionType.DESC
        ? AlchemySortDirectionType.DESC
        : AlchemySortDirectionType.ASC
      : 'none';
  };

  if (loadingFunnelChallenges && !funnelChallengeList) {
    return (
      <Styled.LoadingContainer>
        <LoadingIndicator inline />
      </Styled.LoadingContainer>
    );
  }

  const challenges = funnelChallengeList?.challenges || [];

  const ideasDataCount = ideasData?.ideasAndCount?.count;

  return (
    <Grid key="ideasByPhaseStats">
      <CustomFilters
        userFieldOptions={userFieldOptions}
        communityUserFields={communityUserFields}
        countries={countries}
        userFieldUpdate={setUserFieldOptions}
        setSkip={setSkip}
      />
      <GridHeader key="challengeSelectionHeader">
        {intl.formatMessage({ id: 'show', defaultMessage: 'Show' })}
      </GridHeader>
      <GridRow key="challengeSelection">
        <Select
          defaultValue={challenge}
          options={[
            {
              label: intl.formatMessage({ id: 'selectAChallenge', defaultMessage: 'Select a challenge' }),
              value: ChallengeSelectionType.SELECT,
              'data-testid': `challege-${ChallengeSelectionType.SELECT}`
            },
            ...challenges.map((challenge) => ({
              label: challenge.title,
              value: challenge.id,
              'data-testid': `challege-${challenge.title}`
            }))
          ]}
          onChange={(challengeSelection) => {
            setChallenge(challengeSelection.value);
          }}
        />
      </GridRow>
      <GridRow key="updateButton">
        <Button
          priority="secondary"
          onClick={() => {
            getChallenge({ variables: { id: challenge } });
            getFilteredUserIds({ variables: { filters: { userFields } } });
          }}
        >
          {intl.formatMessage({ id: 'update', defaultMessage: 'Update' })}
        </Button>
      </GridRow>
      <GridRow key="chart">
        {challenge !== ChallengeSelectionType.SELECT && chartData && (
          <HighchartsReact highcharts={Highcharts} options={highchartsOptions} />
        )}
      </GridRow>
      {challenge && ideasDataCount && ideasDataCount > 0 ? (
        <>
          <GridRow key="table" justify="center">
            <GridCol>
              <IdeasTable
                getColumnSortDirection={getColumnSortDirection}
                ideasAndCount={ideasData?.ideasAndCount || { count: 0, rows: [] }}
                intl={intl}
                sortIdeas={sortIdeas}
              />
            </GridCol>
            <GridCol>
              <Pagination
                numPages={Math.ceil((ideasDataCount || 1) / IDEAS_PER_PAGE)}
                activeIndex={offset}
                onPageClick={(page) => {
                  setSkip(false);
                  setOffset(page.index as number);
                }}
              />
            </GridCol>
          </GridRow>
          <GridRow key="exportData">
            <Button
              priority="secondary"
              onClick={() => {
                const exportUrl = new URL(EXPORT_TO_CSV_ENDPOINT + challenge.toString(), `https://${community.url}`);

                if (phase) {
                  exportUrl.searchParams.append('forPhase', phase.toString());
                }

                exportUrl.searchParams.append('includeComments', '0');

                if (Object.keys(userFieldOptions).length > 0) {
                  exportUrl.searchParams.append(
                    'customFiltersUserIds',
                    (customFiltersUserIds?.filteredUsers || []).toString()
                  );

                  exportUrl.searchParams.append('ideaIds', (coauthorIdeaIds?.coauthorIdeas || []).toString());
                }

                window.location = exportUrl as unknown as Location;
              }}
            >
              {intl.formatMessage({ id: 'exportData', defaultMessage: 'Export Data' })}
            </Button>
          </GridRow>
        </>
      ) : (
        challenge &&
        ideasDataCount === 0 && (
          <ErrorMessage
            message={intl.formatMessage({ id: 'noIdeasWereFound', defaultMessage: 'No ideas were found' })}
            details={intl.formatMessage({
              id: 'pleaseAdjustYourSearch',
              defaultMessage: 'Please adjust your search parameters and try again.'
            })}
          />
        )
      )}
    </Grid>
  );
};
