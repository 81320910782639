import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, ErrorMessage, LoadingIndicator, Select } from '@m/alchemy-ui';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useIntl } from 'react-intl';
import Exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import {
  type ChallengesQuery,
  type CoauthorIdeasQuery,
  type FilteredUsersQuery,
  GET_CHALLENGES,
  GET_COAUTHOR_IDEAS,
  GET_COUNTRIES,
  GET_THEME_IDEAS_AND_COUNT,
  GET_USERFIELDS_FILTERED_USERIDS,
  type GetCountriesQuery,
  type GetThemeIdeasAndCountQuery,
  type GetThemeIdeasSummaryQuery
} from '../../graphql/queries';

import { useUserFields } from '../../hooks';
import { CommunityContext } from '../../context';
import { GET_THEME_IDEAS_SUMMARY } from '../../graphql/queries/stat';
import { Grid, GridCol, GridHeader, GridRow, Pagination } from './Analytics.styled';

import * as Styled from './components/SearchFilters.styled';
import { downloadIcon, ideasByThemeChartOptions } from './HighchartsData';
import { CustomFilters } from './CustomFilters';
import { type ChallengeListItemType, type ThemeIdeasSummaryItemType, type UserFieldType } from './types';
import { IdeasExportButton, IdeasTable } from './components';

import {
  CHART_MAX_COLUMN_COUNT,
  EXPORT_TO_CSV_ENDPOINT,
  IDEAS_DEFAULT_OFFSET,
  IDEAS_DEFAULT_SORT,
  IDEAS_PER_PAGE
} from './constants';

import {
  AlchemySortDirectionType,
  ChallengeSelectionType,
  CoreApiSortDirectionType,
  ThemeSelectionType
} from './enums';

// Enable data export for chart
Exporting(Highcharts);
// Enable display of no data for chart
NoDataToDisplay(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.download = downloadIcon;

export const IdeasByThemeStats = () => {
  const intl = useIntl();

  const community = useContext(CommunityContext);

  const { data: communityUserFields } = useUserFields();

  const defaultUserFieldOptions: UserFieldType = {};
  const [userFieldOptions, setUserFieldOptions] = useState(defaultUserFieldOptions);
  const [countries, setCountries] = useState<string[]>([]);

  const [challenge, setChallenge] = useState<string>(ChallengeSelectionType.PUBLISHED);
  const [theme, setTheme] = useState<string>(ThemeSelectionType.PUBLISHED);

  const defaultChartOptions = useMemo(() => ideasByThemeChartOptions(intl), [intl]);

  const { data: countryList } = useQuery<GetCountriesQuery>(GET_COUNTRIES, {
    variables: { id: community.id },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (countryList?.community) {
      countryList.community && setCountries(countryList.community.countries as string[]);
    }
  }, [countryList]);

  const { loading: loadingChallenges, data: challengeList } = useQuery<ChallengesQuery>(GET_CHALLENGES, {
    variables: { order: ['title'] },
    fetchPolicy: 'cache-first'
  });

  const { data: customFiltersUserIds } = useQuery<FilteredUsersQuery>(GET_USERFIELDS_FILTERED_USERIDS, {
    variables: { filters: { userFields: JSON.stringify(userFieldOptions) } },
    fetchPolicy: 'cache-first'
  });

  const { data: coauthorIdeaIds } = useQuery<CoauthorIdeasQuery>(GET_COAUTHOR_IDEAS, {
    variables: {
      customFiltersUserIds: customFiltersUserIds?.filteredUsers || [],
      filters: { userFields: JSON.stringify(userFieldOptions) }
    },
    // There is no need to request coauthors data in case custom fields are not selected
    skip: !customFiltersUserIds
  });

  const [themeIdeasSummaryQueryVariables, setThemeIdeasSummaryQueryVariables] = useState({
    challengeSelection: challenge,
    themeSelection: theme,
    filters: { userFields: JSON.stringify(userFieldOptions) }
  });

  const { data: chartData, loading: loadingChart } = useQuery<GetThemeIdeasSummaryQuery>(GET_THEME_IDEAS_SUMMARY, {
    variables: themeIdeasSummaryQueryVariables
  });

  const [themeIdeasQueryVariables, setThemeIdeasQueryVariables] = useState({
    challengeSelection: challenge,
    themeSelection: theme,
    filters: { userFields: JSON.stringify(userFieldOptions) },
    order: [IDEAS_DEFAULT_SORT],
    offset: IDEAS_DEFAULT_OFFSET
  });

  const isThemeSelected = useMemo(
    () => themeIdeasQueryVariables.themeSelection !== ThemeSelectionType.PUBLISHED,
    [themeIdeasQueryVariables]
  );

  const { data: ideasData, loading: loadingIdeas } = useQuery<GetThemeIdeasAndCountQuery>(GET_THEME_IDEAS_AND_COUNT, {
    variables: themeIdeasQueryVariables,
    // If a specific theme is not selected, do not request any ideas data
    skip: !isThemeSelected
  });

  useEffect(() => {
    setThemeIdeasQueryVariables({
      challengeSelection: challenge,
      themeSelection: theme,
      filters: { userFields: JSON.stringify(userFieldOptions) },
      order: [IDEAS_DEFAULT_SORT],
      offset: IDEAS_DEFAULT_OFFSET
    });
  }, [challenge, setThemeIdeasQueryVariables, theme, userFieldOptions]);

  const sortIdeas = (column: string) => {
    const { order } = themeIdeasQueryVariables;
    const [sortColumn, sortDirection] = order[0];
    const newOrder = [
      [
        column,
        column === sortColumn && sortDirection === CoreApiSortDirectionType.ASC
          ? CoreApiSortDirectionType.DESC
          : CoreApiSortDirectionType.ASC
      ]
    ];

    setThemeIdeasQueryVariables({
      ...themeIdeasQueryVariables,
      order: newOrder
    });
  };

  const getColumnSortDirection = (column: string) => {
    const { order } = themeIdeasQueryVariables;
    const [sortColumn, sortDirection] = order[0];
    return sortColumn === column
      ? sortDirection === CoreApiSortDirectionType.DESC
        ? AlchemySortDirectionType.DESC
        : AlchemySortDirectionType.ASC
      : 'none';
  };

  const getExportIdeasUrl = (): URL => {
    let challengeId;

    if (isThemeSelected) {
      challengeId = theme;
    } else {
      challengeId =
        challenge === ChallengeSelectionType.PUBLISHED || challenge === ChallengeSelectionType.ALL ? '0' : challenge;
    }

    const baseUrl = `https://${community.url}`;
    const exportUrl = new URL(EXPORT_TO_CSV_ENDPOINT + String(challengeId), baseUrl);

    exportUrl.searchParams.append('includeUnpublished', challenge === ChallengeSelectionType.PUBLISHED ? '0' : '1');
    exportUrl.searchParams.append('includeComments', '0');

    if (customFiltersUserIds && customFiltersUserIds.filteredUsers.length > 0) {
      exportUrl.searchParams.append('customFiltersUserIds', (customFiltersUserIds?.filteredUsers || []).toString());

      exportUrl.searchParams.append('ideaIds', (coauthorIdeaIds?.coauthorIdeas || []).toString());
    }

    return exportUrl;
  };

  const chartOptions = useMemo(() => {
    const data = chartData?.themeIdeasSummary ? chartData.themeIdeasSummary : [];

    return {
      ...defaultChartOptions,
      xAxis: [
        {
          ...defaultChartOptions.xAxis[0],
          ...(data.length >= CHART_MAX_COLUMN_COUNT && {
            max: CHART_MAX_COLUMN_COUNT,
            scrollbar: {
              enabled: true
            }
          }),
          categories: data ? data.map(({ themeName }: ThemeIdeasSummaryItemType) => themeName) : []
        },
        {
          ...defaultChartOptions.xAxis[1],
          categories: data ? data.map(({ themeId }: ThemeIdeasSummaryItemType) => themeId) : []
        }
      ],
      series: [
        {
          ...defaultChartOptions.series[0],
          data: data ? data.map(({ ideaCount }: ThemeIdeasSummaryItemType) => ideaCount) : []
        }
      ],
      plotOptions: {
        ...defaultChartOptions.plotOptions,
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: (event: Highcharts.PointClickEventObject) => {
                const themeIds = event.point.series.chart.xAxis[1].categories;
                const currentThemeIndex = event.point.x;
                const currentThemeId = String(themeIds[currentThemeIndex]);

                setTheme(currentThemeId);
              }
            }
          }
        }
      }
    };
  }, [chartData, defaultChartOptions, setTheme]);

  if ((loadingChallenges && !challengeList) || (loadingChart && !chartData)) {
    return (
      <Styled.LoadingContainer>
        <LoadingIndicator inline />
      </Styled.LoadingContainer>
    );
  }

  return (
    <Grid key="ideasByThemeStats">
      <CustomFilters
        userFieldOptions={userFieldOptions}
        communityUserFields={communityUserFields}
        countries={countries}
        userFieldUpdate={(userField: UserFieldType) => {
          // Reset selected theme - means "no selected theme"
          setTheme(ThemeSelectionType.PUBLISHED);
          setUserFieldOptions(userField);
        }}
      />
      <GridHeader key="challengeSelectionHeader">
        {intl.formatMessage({ id: 'show', defaultMessage: 'Show' })}
      </GridHeader>
      <GridRow key="challengeSelection">
        <Select
          defaultValue={challenge}
          options={[
            {
              label: intl.formatMessage({ id: 'allPublishedChallenges', defaultMessage: 'All Published Challenges' }),
              value: ChallengeSelectionType.PUBLISHED,
              'data-testid': `challenge-${ChallengeSelectionType.PUBLISHED}`
            },
            {
              label: intl.formatMessage({ id: 'allChallenges', defaultMessage: 'All Challenges' }),
              value: ChallengeSelectionType.ALL,
              'data-testid': `challenge-${ChallengeSelectionType.ALL}`
            },
            ...((challengeList as ChallengesQuery) &&
              (challengeList?.challenges as ChallengeListItemType[]) &&
              (challengeList?.challenges as ChallengeListItemType[]).map((challenge: ChallengeListItemType) => ({
                label: challenge.title,
                value: challenge.id,
                'data-testid': `challenge-${challenge.title}`
              })))
          ]}
          onChange={(e) => {
            setChallenge(e.value);
            // And additionally reset a previously selected theme
            setTheme(ThemeSelectionType.PUBLISHED);
          }}
        />
      </GridRow>
      <GridRow key="updateButton">
        <Button
          priority="secondary"
          onClick={() => {
            setThemeIdeasSummaryQueryVariables({
              challengeSelection: challenge,
              themeSelection: ThemeSelectionType.PUBLISHED,
              filters: { userFields: JSON.stringify(userFieldOptions) }
            });
          }}
        >
          {intl.formatMessage({ id: 'update', defaultMessage: 'Update' })}
        </Button>
      </GridRow>
      <GridRow key="chart">
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </GridRow>
      {isThemeSelected && (
        <GridRow key="table" justify="center">
          {loadingIdeas && !ideasData ? (
            <Styled.LoadingContainer>
              <LoadingIndicator inline />
            </Styled.LoadingContainer>
          ) : ideasData?.themeIdeasAndCount && ideasData.themeIdeasAndCount.count > 0 ? (
            <>
              <GridCol>
                <IdeasTable
                  getColumnSortDirection={getColumnSortDirection}
                  ideasAndCount={ideasData.themeIdeasAndCount}
                  intl={intl}
                  sortIdeas={sortIdeas}
                />
              </GridCol>
              <GridCol>
                {/* Even if there is only 1 page, we should keep the pagination */}
                <Pagination
                  numPages={Math.ceil(ideasData.themeIdeasAndCount.count / IDEAS_PER_PAGE)}
                  activeIndex={Math.ceil(themeIdeasQueryVariables.offset / IDEAS_PER_PAGE)}
                  onPageClick={(page) => {
                    const pageIndex = page.index as number;
                    setThemeIdeasQueryVariables({
                      ...themeIdeasQueryVariables,
                      offset: pageIndex * IDEAS_PER_PAGE
                    });
                  }}
                />
              </GridCol>
            </>
          ) : (
            <ErrorMessage
              message={intl.formatMessage({ id: 'noIdeasWereFound', defaultMessage: 'No ideas were found' })}
              details={intl.formatMessage({
                id: 'pleaseAdjustYourSearch',
                defaultMessage: 'Please adjust your search parameters and try again.'
              })}
            />
          )}
        </GridRow>
      )}
      {isThemeSelected && ideasData && ideasData?.themeIdeasAndCount?.count > 0 && (
        <GridRow key="exportData">
          <IdeasExportButton exportUrl={getExportIdeasUrl()} intl={intl} />
        </GridRow>
      )}
    </Grid>
  );
};
