import { Button, MenuButton, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const CustomMenuFooter = styled.div`
  padding: ${units(1)} ${units(2)};
`;

export const Container = styled.div`
  display: inline-block;
  text-align: left;
`;

export const ButtonWide = styled(Button)`
  flex: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10;
  }
`;

export const SubscribeMenuButton = styled(MenuButton)<{ color: string }>`
  display: inline-block;
  position: relative;
  min-width: 40px;
  padding: 8px 16px;
  background: ${({ color }) => color};
  color: white;
  cursor: pointer;
  text-align: center;
  border: 1px solid ${({ color }) => color}65;
  font:
    700 14px 'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    helvetica,
    arial,
    sans-serif;
  line-height: 1.35;
  transition: background 0.3s ease-in-out;
  word-break: keep-all;

  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:focusout,
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
    background: ${({ color }) => color}18;
  }
`;
