import styled from 'styled-components';
import {
  Field,
  Grid,
  GridCol,
  GridRow,
  Radio,
  SlateRTE,
  type SlateToolbarControl,
  typeStyle,
  units
} from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { type FormikProps } from 'formik';
import { useFormikContext } from 'formik';
import { Container } from '../../../common/components';
import { MilestoneMessagesType } from '../../../common/enums';
import { type Milestone } from '../../../common/core-api';
import { MilestoneType } from '../../../common/core-api';
import { HelpText, Title } from './MilestoneForm.styled';

const richTextEditorConfig = {
  buttons: ['bold', 'italic', 'unorderedList', 'undo', 'redo'] as SlateToolbarControl[]
};

const SubTitle = styled.div`
  ${typeStyle('actionM')};
  margin-bottom: ${units(1)};
`;

export const MilestoneMessageRadio = ({
  title,
  checked,
  onChange,
  messageType
}: {
  readonly title: string;
  readonly checked: boolean;
  readonly onChange: () => void;
  readonly messageType: MilestoneMessagesType;
}) => {
  const intl = useIntl();
  const { values, setFieldValue }: FormikProps<Milestone> = useFormikContext();

  const {
    type,
    voters,
    approachMessageToAuthors,
    approachMessageToUsers,
    approachEmailMessageToModerators,
    approachEmailMessageToVoters,
    reachedEmailMessageToAuthors
  } = values;

  const defaultValues = {
    approachMessageToAuthors,
    approachMessageToUsers,
    approachEmailMessageToModerators,
    approachEmailMessageToVoters,
    reachedEmailMessageToAuthors
  };

  let emailToVotersDisabled = true;
  if (type === MilestoneType.VOTE) {
    emailToVotersDisabled =
      (voters?.users?.length === 0 && voters?.groups?.length === 0) ||
      Boolean(voters?.groups?.find((group) => group.id === '0'));
  }

  return (
    <Container>
      <Title>{title}</Title>
      <Radio
        label={intl.formatMessage({
          id: 'useDefaultMessage',
          defaultMessage: 'Use default system generated message'
        })}
        name="useDefaultMessage"
        data-testid="useDefaultMessage"
        checked={checked}
        onChange={onChange}
      />
      <Radio
        label={intl.formatMessage({
          id: 'useOwnMessage',
          defaultMessage: 'I would like to write my own message'
        })}
        name="useOwnMessage"
        data-testid="useOwnMessage"
        checked={!checked}
        onChange={onChange}
      />
      {messageType === MilestoneMessagesType.APPROACH && !checked && (
        <Grid>
          <Container>
            <GridRow>
              <GridCol span={{ small: 12, medium: 6 }}>
                <Field
                  isFullWidth
                  labelVariant="emphasized"
                  label={intl.formatMessage({
                    id: 'messageForIdeaAuthor',
                    defaultMessage: 'Message for idea author'
                  })}
                  input={
                    <SlateRTE
                      data-testid="approachMessageToAuthors"
                      initialValue={SlateRTE.deserialize(defaultValues.approachMessageToAuthors || '')}
                      controls={richTextEditorConfig.buttons}
                      onChange={(value) => setFieldValue('approachMessageToAuthors', SlateRTE.serialize(value))}
                      key={1}
                    />
                  }
                  helpText={
                    <HelpText>
                      {intl.formatMessage({
                        id: 'approachMessageToAuthorsHelpText',
                        defaultMessage:
                          'This message is shown on the idea page, under the milestones. Only IDEA AUTHORS will see it (not all users). You can use this space to provide information or instructions to authors.'
                      })}
                    </HelpText>
                  }
                />
              </GridCol>
              {type !== MilestoneType.SUBMITTED && (
                <>
                  <GridCol span={{ small: 12, medium: 6 }}>
                    <Field
                      isFullWidth
                      labelVariant="emphasized"
                      label={intl.formatMessage({
                        id: 'messageForUsers',
                        defaultMessage: 'Message for users'
                      })}
                      input={
                        <SlateRTE
                          data-testid="approachMessageToUsers"
                          initialValue={SlateRTE.deserialize(defaultValues.approachMessageToUsers || '')}
                          controls={richTextEditorConfig.buttons}
                          onChange={(value) => setFieldValue('approachMessageToUsers', SlateRTE.serialize(value))}
                          key={2}
                        />
                      }
                      helpText={
                        <HelpText>
                          {intl.formatMessage({
                            id: 'approachMessageToUsersHelpText',
                            defaultMessage:
                              'This message is shown on the idea page, under the milestones. Only USERS will see it (not the idea author). You can use this space to provide information or instructions to users.'
                          })}
                        </HelpText>
                      }
                    />
                  </GridCol>
                </>
              )}
            </GridRow>
          </Container>
          {type !== MilestoneType.SUBMITTED && type !== MilestoneType.REFINE && (
            <Container>
              <GridRow>
                <GridCol span={{ small: 12, medium: 6 }}>
                  <Field
                    isFullWidth
                    labelVariant="emphasized"
                    label={intl.formatMessage({
                      id: 'emailForModerators',
                      defaultMessage: 'Email for moderators'
                    })}
                    input={
                      <SlateRTE
                        data-testid="approachEmailMessageToModerators"
                        initialValue={SlateRTE.deserialize(defaultValues.approachEmailMessageToModerators || '')}
                        controls={richTextEditorConfig.buttons}
                        onChange={(value) =>
                          setFieldValue('approachEmailMessageToModerators', SlateRTE.serialize(value))
                        }
                        key={3}
                      />
                    }
                    helpText={
                      <HelpText>
                        {intl.formatMessage({
                          id: 'approachEmailMessageToModeratorsHelpText',
                          defaultMessage:
                            'An email will be sent to moderators to tell them that an idea is approaching this milestone. Use this space to add your own text to the email.'
                        })}
                      </HelpText>
                    }
                  />
                </GridCol>
                {type === MilestoneType.VOTE && (
                  <>
                    <GridCol span={{ small: 12, medium: 6 }}>
                      {emailToVotersDisabled ? (
                        <div>
                          <SubTitle>
                            {intl.formatMessage({
                              id: 'emailForVoters',
                              defaultMessage: 'Email for voters'
                            })}
                          </SubTitle>
                          <FormattedMessage
                            id="emailForVotersDisabledMessage"
                            defaultMessage="This option is only available when individuals or groups of users can vote. You cannot send an email when Everyone can vote."
                          />
                        </div>
                      ) : (
                        <Field
                          isFullWidth
                          labelVariant="emphasized"
                          label={intl.formatMessage({
                            id: 'emailForVoters',
                            defaultMessage: 'Email for voters'
                          })}
                          input={
                            <SlateRTE
                              data-testid="approachEmailMessageToVoters"
                              initialValue={SlateRTE.deserialize(defaultValues.approachEmailMessageToVoters || '')}
                              controls={richTextEditorConfig.buttons}
                              onChange={(value) =>
                                setFieldValue('approachEmailMessageToVoters', SlateRTE.serialize(value))
                              }
                              key={4}
                            />
                          }
                          helpText={
                            <HelpText>
                              {intl.formatMessage({
                                id: 'approachEmailMessageToVotersHelpText',
                                defaultMessage:
                                  'This email will be sent to the users specified under "Users who can vote".'
                              })}
                            </HelpText>
                          }
                        />
                      )}
                    </GridCol>
                  </>
                )}
              </GridRow>
            </Container>
          )}
        </Grid>
      )}
      {messageType === MilestoneMessagesType.REACH && !checked && (
        <Grid>
          <Container>
            <GridRow>
              <GridCol span={{ small: 12, medium: 6 }}>
                <Field
                  isFullWidth
                  input={
                    <SlateRTE
                      data-testid="reachedEmailMessageToAuthors"
                      initialValue={SlateRTE.deserialize(defaultValues.reachedEmailMessageToAuthors || '')}
                      controls={richTextEditorConfig.buttons}
                      onChange={(value) => setFieldValue('reachedEmailMessageToAuthors', SlateRTE.serialize(value))}
                      key={5}
                    />
                  }
                  helpText={
                    <HelpText>
                      {intl.formatMessage({
                        id: 'reachedEmailMessageToAuthorsHelpText',
                        defaultMessage:
                          'An email will be sent to the idea author to thell them that their idea has reached this milestone. Use this space to add your own text to the email. Ideally you should explain what the next milestone is and what they need to do to reach it.'
                      })}
                    </HelpText>
                  }
                />
              </GridCol>
            </GridRow>
          </Container>
        </Grid>
      )}
    </Container>
  );
};
