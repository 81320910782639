import styled from 'styled-components';

export const SubscribeSingleButtonStyle = styled.button<{ color: string; singleMode: boolean }>`
  display: inline-block;
  position: relative;
  min-width: 40px;
  background: #ffffff;
  color: ${({ color }) => color};
  cursor: pointer;
  text-align: center;
  font:
    700 14px 'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    helvetica,
    arial,
    sans-serif;
  line-height: 1.35;
  transition: background 0.3s ease-in-out;
  word-break: keep-all;

  ${({ singleMode, color }) =>
    singleMode &&
    `
    border: 1px solid ${color}65;
    border-radius: 18px;
    padding: 8px 16px;

    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
      background: ${color}18;
    }
  `}

  ${({ singleMode, color }) =>
    !singleMode &&
    `
    padding: 8px 8px 8px 16px;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    border-left: 1px solid ${color}65;
    border-top: 1px solid ${color}65;
    border-bottom: 1px solid ${color}65;
    border-right: 0px;
    cursor: default;
  `}
`;
