import { type ChangeEvent, useContext } from 'react';
import { Checkbox, CheckboxGroup, Field, FieldStatus, type Option, Radio, Select, snap, units } from '@m/alchemy-ui';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { type FormikProps, useFormikContext } from 'formik';
import { type IChallenge } from '../../../common/interfaces';
import { ShortInput, SubTitle } from '../../../common/components';
import { SelectorType } from '../../../common/enums';
import { IdeaSubmissionsType, type Phase } from '../../../common/core-api';
import { useFieldError } from '../../../hooks';
import { CommunityContext } from '../../../context/Community';

type BinaryKeyType = Record<string, unknown> | number | string | null;

interface CheckboxGroupProps {
  setSize: boolean;
}

const StyledCheckboxGroup = styled(CheckboxGroup)<CheckboxGroupProps>`
  height: ${(props) => (props.setSize ? snap(400) : '')};
  & > * {
    max-width: ${(props) => (props.setSize ? '50%' : '')};
  }
`;

const CheckboxGroupChild = styled.div`
  margin: ${units(0.5)} 0 0 ${units(3)};
`;

const StyledLabel = styled.label`
  font-weight: normal;
`;

const StyledSelectLabelHeader = styled.div`
  &:before {
    content: '— ';
  }
  &:after {
    content: ' —';
  }
`;

const PrivateSubmission = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

interface CheckboxWrapperProps {
  indented: boolean | undefined;
}

const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  margin-left: ${(props) => (props.indented ? units(3) : 0)};
`;

export const AccessOptions = ({ type }: { readonly type: SelectorType }) => {
  const { values, setFieldValue, handleChange, handleBlur }: FormikProps<IChallenge & Phase> = useFormikContext();

  const [fieldError] = useFieldError();

  const { options } = values.access;

  const community = useContext(CommunityContext);

  const handleSelectionChange = (selection: BinaryKeyType | BinaryKeyType[]) => {
    if (!Array.isArray(selection)) {
      return;
    }

    const changedItem = selection[selection.length - 1];

    if (changedItem && typeof changedItem === 'object') {
      setFieldValue(String(changedItem.name), !changedItem.checked);
    }
  };

  const ideaSubmissionsTypeSelectData = [
    {
      label: (
        <StyledSelectLabelHeader>
          <FormattedMessage id="selectIdeaSubmissionsType" defaultMessage="Select Idea Submissions Type" />
        </StyledSelectLabelHeader>
      ),
      value: '',
      disabled: true,
      'data-testid': 'ideaSubmissionsSelectOption'
    },
    {
      label: <FormattedMessage id="standard" defaultMessage="Standard" />,
      value: IdeaSubmissionsType.STANDARD,
      'data-testid': `ideaSubmissions-${IdeaSubmissionsType.STANDARD}`
    },
    {
      label: <FormattedMessage id="joint" defaultMessage="Joint" />,
      value: IdeaSubmissionsType.JOINT,
      'data-testid': `ideaSubmissions-${IdeaSubmissionsType.JOINT}`,
      description: (
        <FormattedMessage id="jointDescription" defaultMessage="Allow participants the option to submit with others" />
      )
    }
  ];

  if (community.options?.anonymousSubmissionsEnabled) {
    ideaSubmissionsTypeSelectData.push(
      {
        label: <FormattedMessage id="anonymousOptional" defaultMessage="Anonymous optional" />,
        value: IdeaSubmissionsType.ANONYMOUS_OPTIONAL,
        'data-testid': `ideaSubmissions-${IdeaSubmissionsType.ANONYMOUS_OPTIONAL}`,
        description: (
          <FormattedMessage
            id="anonymousOptionalDescription"
            defaultMessage="Allow participants the option to submit anonymously"
          />
        )
      },
      {
        label: <FormattedMessage id="anonymousEnforced" defaultMessage="Anonymous enforced" />,
        value: IdeaSubmissionsType.ANONYMOUS_ENFORCED,
        'data-testid': `ideaSubmissions-${IdeaSubmissionsType.ANONYMOUS_ENFORCED}`,
        description: (
          <FormattedMessage
            id="anonymousEnforcedDescription"
            defaultMessage="Limit participants to submit anonymously"
          />
        )
      }
    );
  }

  const handleIdeaSubmissionsTypeChange = ({ value }: Option) => {
    setFieldValue('access.options.ideaSubmissionsType', value);
  };

  let selections = [
    {
      name: 'options.section.title',
      label: <FormattedMessage id="challengeOptions" defaultMessage="Challenge options" />
    },
    {
      name: 'access.options.ideasVisible',
      label: <FormattedMessage id="visible" defaultMessage="Visible" />,
      checked: options?.ideasVisible
    },
    {
      name: 'access.options.ideaSubmissionEnabled',
      label: <FormattedMessage id="postIdeas" defaultMessage="Post ideas" />,
      checked: options?.ideaSubmissionEnabled,
      children: (
        <CheckboxGroupChild>
          <Field
            data-testid="ideaSubmissionsType"
            label={<FormattedMessage id="ideaSubmissionsType" defaultMessage="Idea submissions type" />}
            labelVariant="normal"
            disabled={!options?.ideaSubmissionEnabled}
            input={
              <Select
                name="access.options.ideaSubmissionsType"
                defaultValue={IdeaSubmissionsType.STANDARD}
                value={options?.ideaSubmissionsType}
                options={ideaSubmissionsTypeSelectData}
                onChange={handleIdeaSubmissionsTypeChange}
              />
            }
          />
        </CheckboxGroupChild>
      )
    },
    {
      name: 'access.options.voteOnIdeasEnabled',
      label: <FormattedMessage id="voteIdeas" defaultMessage="Vote on ideas" />,
      checked: options?.voteOnIdeasEnabled
    },
    {
      name: 'access.options.comments.enabled',
      label: <FormattedMessage id="commentsEnabled" defaultMessage="Post comments" />,
      checked: options?.comments.enabled
    },
    {
      name: 'access.options.comments.attachmentsEnabled',
      label: <FormattedMessage id="commentsAttachmentsEnabled" defaultMessage="Allow comment attachments" />,
      checked: options?.comments.attachmentsEnabled,
      indented: true,
      disabled: !options?.comments.enabled
    },
    {
      name: 'access.options.voteOnCommentsEnabled',
      label: <FormattedMessage id="commentsVoteEnabled" defaultMessage="Vote on comments" />,
      checked: options?.voteOnCommentsEnabled
    },
    {
      name: 'access.options.showClosedEnabled',
      label: <FormattedMessage id="showClosed" defaultMessage="Show ‘closed’" />,
      checked: options?.showClosedEnabled
    },
    {
      name: 'access.options.ideaEditLock.enabled',
      label: <FormattedMessage id="lockEdit" defaultMessage="Lock edit" />,
      checked: options?.ideaEditLock.enabled,
      children: (
        <CheckboxGroupChild>
          <StyledLabel>
            <FormattedMessage
              id="ideaLockTimeout"
              defaultMessage="Users will be able to edit ideas for {input} minutes after posting"
              values={{
                input: (
                  <ShortInput
                    name="access.options.ideaEditLock.graceMinutes"
                    value={options?.ideaEditLock.graceMinutes || undefined}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )
              }}
            />
            {fieldError('access.options.ideaEditLock.graceMinutes') && (
              <FieldStatus message="" {...fieldError('access.options.ideaEditLock.graceMinutes')} />
            )}
          </StyledLabel>
        </CheckboxGroupChild>
      )
    }
  ];

  if (community.options?.anonymousSubmissionsEnabled) {
    selections.splice(6, 0, {
      name: 'access.options.comments.anonymousEnabled',
      label: <FormattedMessage id="commentsAnonymousEnabled" defaultMessage="Allow anonymous comments" />,
      checked: options?.comments.anonymousEnabled,
      indented: true,
      disabled: !options?.comments.enabled
    });
  }

  if (type === SelectorType.PHASE) {
    selections = [
      ...selections,
      {
        name: 'access.options.phaseAutoAdvancement',
        label: (
          <FormattedMessage
            id="phaseAutoAdvancement"
            defaultMessage="Automatically advance all ideas to the next phase"
          />
        ),
        checked: Boolean(options?.phaseAutoAdvancement)
      },
      {
        name: 'access.options.phaseResetComments',
        label: <FormattedMessage id="phaseResetComments" defaultMessage="Reset all comments for this phase" />,
        checked: Boolean(options?.phaseResetComments)
      },
      {
        name: 'access.options.phaseResetVotes',
        label: <FormattedMessage id="phaseResetVotes" defaultMessage="Reset all votes for this phase" />,
        checked: Boolean(options?.phaseResetVotes)
      }
    ];
  }

  if (!values.isMilestone) {
    selections = [
      ...selections,
      {
        name: 'access.options.privateSubmissionEnabled',
        label: <FormattedMessage id="privateSubmissionEnabled" defaultMessage="Enable private submissions" />,
        checked: options?.privateSubmissionEnabled
      }
    ];
  }

  // keep as last for Phase case
  selections = [
    ...selections,
    {
      name: 'options.section.title',
      label: <FormattedMessage id="ideaShare.ideaSharing" defaultMessage="Idea sharing" />
    },
    {
      name: 'access.options.shareFacebookEnabled',
      label: <FormattedMessage id="ideaShare.enableSharingOnFacebook" defaultMessage="Enable sharing on Facebook" />,
      checked: Boolean(options?.shareFacebookEnabled)
    },
    {
      name: 'access.options.shareTwitterEnabled',
      label: <FormattedMessage id="ideaShare.enableSharingOnX" defaultMessage="Enable sharing on X" />,
      checked: Boolean(options?.shareTwitterEnabled)
    },
    {
      name: 'access.options.shareLinkedInEnabled',
      label: <FormattedMessage id="ideaShare.enableSharingOnLinkedIn" defaultMessage="Enable sharing on LinkedIn" />,
      checked: Boolean(options?.shareLinkedInEnabled)
    },
    {
      name: 'access.options.copyUrlEnabled',
      label: (
        <FormattedMessage
          id="ideaShare.enableShortcutToCopyUrlLink"
          defaultMessage="Enable a shortcut to copy URL link"
        />
      ),
      checked: Boolean(options?.copyUrlEnabled)
    }
  ];

  return (
    <>
      {type !== SelectorType.PHASE && <SubTitle marginStrategy="bottom" />}
      <StyledCheckboxGroup
        setSize={type !== SelectorType.PHASE}
        selection={selections.filter((selection) => selection.checked).map((selection) => selection.name)}
        onChange={(selection) => handleSelectionChange(selection)}
      >
        {({ isSelected, handleChange }) =>
          selections.map((selection) =>
            selection.name === 'access.options.privateSubmissionEnabled' ? (
              <PrivateSubmission key={selection.name}>
                <SubTitle>
                  <FormattedMessage id="ideaVisibility" defaultMessage="Idea visibility" />
                </SubTitle>
                <Radio
                  label={
                    <FormattedMessage
                      id="visibleToAnyone"
                      defaultMessage="Visible to anyone with access to the challenge"
                    />
                  }
                  name="visibleToAnyone"
                  data-testid="visibleToAnyone"
                  checked={!selection.checked}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue(selection.name, !e.target.checked)}
                />
                <Radio
                  label={
                    <FormattedMessage
                      id="visibleToModerators"
                      defaultMessage="Visible to challenge moderator(s) only"
                    />
                  }
                  data-testid="visibleToModerators"
                  name="visibleToModerators"
                  checked={selection.checked || false}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue(selection.name, e.target.checked)}
                />
              </PrivateSubmission>
            ) : selection.name === 'options.section.title' ? (
              <SubTitle marginStrategy="bottom">{selection.label}</SubTitle>
            ) : (
              <CheckboxWrapper indented={selection.indented} key={selection.name}>
                <Checkbox
                  label={selection.label}
                  key={selection.name}
                  data-testid={selection.name}
                  name={selection.name}
                  checked={isSelected(selection.name)}
                  onChange={() => handleChange(selection)}
                  disabled={selection.disabled}
                />
                {selection.children}
              </CheckboxWrapper>
            )
          )
        }
      </StyledCheckboxGroup>
    </>
  );
};
