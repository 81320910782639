import { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AppBarButtonGroup,
  AppMenuButton,
  AppMenuList,
  AppMenuListExpandableItem,
  AppMenuListItem,
  AppNavListItem,
  ButtonGroup,
  ElementObserver,
  HelpIcon,
  LanguageIcon,
  LogoutIcon,
  MoreVerticalIcon,
  SettingsIcon,
  SupportTicketSignalOutline,
  TeamIcon,
  ZeroStateAddIcon
} from '@m/alchemy-ui';
import { default as medalliaAppSwitcher } from '@m/app-switcher';
import { Link, useNavigate } from 'react-location';
import {
  useInvitesDialog,
  useIsTablet,
  useLanguageOverride,
  useLanguageSwitcher,
  usePostIdeaDialog,
  useRegisterDialog,
  useSignInDialog,
  useUserCreatedChallengeForm
} from '../../../hooks';
import { CommunityContext, useRuntimeCommunity, useRuntimeConfig, useRuntimeUser } from '../../../context';
import { MobileMenu, SearchButton } from '../components';
import { type RouteLink } from '../../../common/interfaces/Route';
import * as Styled from '../Navigation.styled';
import { PageType } from '../../../common/enums';
import { getSocialMediaOptions, SocialMediaMenu } from './SocialMediaMenu';

export const languageIsoMatch = (l1: string, l2: string) =>
  l1.toLowerCase().replace(/[^a-zA-Z]/g, '') === l2.toLowerCase().replace(/[^a-zA-Z]/g, '');

export const UserNavigation = ({
  links,
  isMobile = false,
  isLoggedIn,
  hasAdminRights = false
}: {
  links?: RouteLink[];
  isMobile?: boolean;
  isLoggedIn: boolean;
  hasAdminRights: boolean;
}) => {
  const intl = useIntl();
  const { canInvite, permissions, displayName, avatar } = useRuntimeUser();
  const { loadDialog: loadSignInDialog } = useSignInDialog();
  const { loadDialog: loadRegisterDialog } = useRegisterDialog();
  const { loadDialog: loadPostIdeaDialog } = usePostIdeaDialog();
  const { loadDialog: loadUserCreatedChallengeForm } = useUserCreatedChallengeForm();
  const { loadDialog: loadInvitesDialog } = useInvitesDialog();
  const canModerateIdeas = permissions?.isApprovalModerator || permissions?.isModerator;
  const canCreateChallenge = permissions?.isChallengeCreator;
  const languageOverride = useLanguageOverride();
  const { device, idp } = useRuntimeConfig();
  const community = useContext(CommunityContext);
  const switchLanguage = useLanguageSwitcher();
  const isDesktop = device === 'desktop';
  const isAppSwitcherOn = (community?.options?.appSwitcher?.length || 0) > 0;
  const isTablet = useIsTablet();
  const navigate = useNavigate();
  const initialNav = window.location.pathname === '/' ? links?.[0]?.url : window.location.pathname;
  const [mainNavIsActive, setMainNavIsActive] = useState<string | undefined>(initialNav);

  const {
    colors: { userNavBg }
  } = useRuntimeCommunity();

  const socialMediaOptionsMemo = useMemo(() => getSocialMediaOptions(community), [community]);

  useEffect(() => {
    const initAppSwitcher = async () => {
      const opts = {
        iconSizePx: 32,
        skipLogin: true,
        headerText: 'Select Application',
        themeOption: userNavBg?.toLowerCase() === 'dark' ? 'light' : 'dark',
        staticLinks: community?.options?.appSwitcher
      };
      // @ts-expect-error - AppSwitcher doesn't export the type
      await medalliaAppSwitcher(opts);
    };

    isDesktop && isAppSwitcherOn && initAppSwitcher();
  }, [isAppSwitcherOn, community, isDesktop, userNavBg]);

  const mobileMenuButtonActions = useMemo((): { label: string; onClick: () => void }[] => {
    const items = [];

    if (canModerateIdeas) {
      items.push({
        label: intl.formatMessage({ id: 'moderateIdeas', defaultMessage: 'Moderate Ideas' }),
        onClick() {
          window.location.href = '/dashboard';
        }
      });
    }

    if (canCreateChallenge) {
      items.push({
        label: intl.formatMessage({ id: 'createAChallenge', defaultMessage: 'Create a Challenge' }),
        onClick: loadUserCreatedChallengeForm
      });
    }

    socialMediaOptionsMemo.length > 0 &&
      items.push(
        ...socialMediaOptionsMemo.map(({ label, url }) => ({
          label,
          onClick: () => window.open(url, '_blank')
        }))
      );

    return items;
  }, [canModerateIdeas, canCreateChallenge, loadUserCreatedChallengeForm]); // eslint-disable-line

  return (
    // Workaround for the mobile menu becuase of this https://jira.medallia.com/browse/DES-4157
    // Thread: https://medallia.slack.com/archives/C98CWBBPU/p1716565895464159
    // We can remove the mobilemenu after update Alchemy to 40.6.0 or higher
    <>
      {isMobile ? (
        <MobileMenu isLoggedIn={isLoggedIn} links={links} />
      ) : (
        <Styled.AppNav
          items={links?.map(({ linkTitle, newWindow, type, url }, index) => ({
            label: linkTitle || ' ',
            isActive: mainNavIsActive === url,
            id: linkTitle,
            href: '#',
            onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.preventDefault();
              const notFirstElement = index !== 0;
              if (type === PageType.URL && notFirstElement) {
                window.open(url, newWindow ? '_blank' : '_self');
              } else {
                setMainNavIsActive(url);
                navigate({ to: notFirstElement ? url : '/', replace: true });
              }
            }
          }))}
          stringMap={{
            navList: 'Main Menu',
            mobileMenuButtonTooltip: 'This opens the mobile menu',
            mobileMenuIcon: 'open menu',
            overflowIconButton: 'toggle menu'
          }}
        />
      )}
      <AppBarButtonGroup>
        <SearchButton />
        {isMobile || isTablet ? (
          isLoggedIn ? (
            <>
              <ElementObserver>
                {({ observedElementWidth, observeTarget }) => {
                  const hideTextButton = isMobile && ((observedElementWidth || 0) > 140 || observedElementWidth === 0);
                  return (
                    <>
                      <Styled.ButtonWrapper>
                        <Styled.AppNavButton onClick={loadPostIdeaDialog} ref={observeTarget} hide={hideTextButton}>
                          <ZeroStateAddIcon size={12} aria-hidden="true" />
                          {languageOverride(
                            'postAnIdea',
                            intl.formatMessage({ id: 'postAnIdea', defaultMessage: 'Post an Idea' })
                          )}
                        </Styled.AppNavButton>
                      </Styled.ButtonWrapper>

                      <Styled.IconButton
                        icon={<ZeroStateAddIcon size={22} aria-hidden="true" />}
                        tooltipContents={languageOverride(
                          'postAnIdea',
                          intl.formatMessage({ id: 'postAnIdea', defaultMessage: 'Post an Idea' })
                        )}
                        onClick={loadPostIdeaDialog}
                        hide={!hideTextButton}
                      />
                    </>
                  );
                }}
              </ElementObserver>
              {mobileMenuButtonActions.length > 0 && (
                <Styled.MenuButton data-testid="MoreButton" priority="tertiary" actions={mobileMenuButtonActions}>
                  {intl.formatMessage({ id: 'more', defaultMessage: 'More' })}
                </Styled.MenuButton>
              )}
            </>
          ) : (
            <>
              <Styled.AppNavButton priority="secondary" onClick={loadRegisterDialog}>
                <FormattedMessage id="register" defaultMessage="Register" />
              </Styled.AppNavButton>
              <Styled.AppNavButton onClick={loadSignInDialog} alignLeft>
                <FormattedMessage id="signIn" defaultMessage="Sign In" />
              </Styled.AppNavButton>
            </>
          )
        ) : (
          <ButtonGroup>
            <SocialMediaMenu socialMediaOptions={socialMediaOptionsMemo} />
            {isLoggedIn ? (
              <>
                <Styled.AppNavButton onClick={loadPostIdeaDialog}>
                  <ZeroStateAddIcon size={16} aria-hidden="true" />
                  {languageOverride(
                    'postAnIdea',
                    intl.formatMessage({ id: 'postAnIdea', defaultMessage: 'Post an Idea' })
                  )}
                </Styled.AppNavButton>
                <ButtonGroup isJoined>
                  {canModerateIdeas && (
                    <Styled.AppNavButton priority="secondary" href="/dashboard">
                      {intl.formatMessage({ id: 'moderateIdeas', defaultMessage: 'Moderate Ideas' })}
                    </Styled.AppNavButton>
                  )}
                  {canCreateChallenge && (
                    <Styled.AppNavButton priority="secondary" onClick={loadUserCreatedChallengeForm}>
                      {intl.formatMessage({ id: 'createAChallenge', defaultMessage: 'Create a Challenge' })}
                    </Styled.AppNavButton>
                  )}
                </ButtonGroup>

                {isLoggedIn && (
                  <AppNavListItem key="login">
                    <Link to="user/ideastream" data-testid="profile-link">
                      {avatar ? (
                        <Styled.NavProfileImage src={avatar} alt={displayName} data-testid="UserAvatar" />
                      ) : (
                        <Styled.UserIcon aria-hidden="true" />
                      )}
                    </Link>
                    <Styled.AppNavSeparator />
                  </AppNavListItem>
                )}
              </>
            ) : (
              <>
                <Styled.AppNavButton priority="secondary" onClick={loadRegisterDialog}>
                  <FormattedMessage id="register" defaultMessage="Register" />
                </Styled.AppNavButton>
                <Styled.AppNavButton onClick={loadSignInDialog}>
                  <FormattedMessage id="signIn" defaultMessage="Sign In" />
                </Styled.AppNavButton>
              </>
            )}
          </ButtonGroup>
        )}
        {isLoggedIn && isAppSwitcherOn && isDesktop && (
          <Styled.AppSwitcherWrapper>
            <div id="m_app_switcher" data-testid="m_app_switcher_nav" />
          </Styled.AppSwitcherWrapper>
        )}
        <Styled.ButtonWrapper>
          <AppMenuButton
            label={intl.formatMessage({ id: 'more', defaultMessage: 'More' })}
            shouldHideLabel
            mobileMenuMaxHeight="50vh"
            icon={<MoreVerticalIcon size={24} aria-hidden="true" />}
            overlay={() => (
              <AppMenuList>
                <AppMenuListItem icon={<HelpIcon size={16} aria-hidden="true" />}>
                  <a href="https://help.crowdicity.com" target="_blank">
                    <FormattedMessage id="help" defaultMessage="Help" />
                  </a>
                </AppMenuListItem>
                {isLoggedIn && hasAdminRights && (
                  <AppMenuListItem hasDivider icon={<SettingsIcon size={16} aria-hidden="true" />}>
                    <a href="/category/admin_view">
                      <FormattedMessage id="adminSetup" defaultMessage="Admin Setup" />
                    </a>
                  </AppMenuListItem>
                )}
                {isLoggedIn && canInvite && (
                  <AppMenuListItem hasDivider icon={<TeamIcon size={16} aria-hidden="true" />}>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        loadInvitesDialog();
                      }}
                    >
                      <FormattedMessage id="Invite Users" defaultMessage="Invite Users" />
                    </a>
                  </AppMenuListItem>
                )}
                {community?.options?.allowLanguageChoice && (
                  <AppMenuListExpandableItem
                    label={intl.formatMessage({ id: 'languages', defaultMessage: 'Languages' })}
                    icon={<LanguageIcon size={16} aria-hidden="true" />}
                  >
                    <AppMenuList>
                      {(community?.options?.allowedLanguages || []).map(({ name, iso }) => (
                        <AppMenuListItem key={iso} isActive={languageIsoMatch(intl.locale, iso)}>
                          <a href="/" onClick={(e) => switchLanguage(iso, e)}>
                            {name}
                          </a>
                        </AppMenuListItem>
                      ))}
                    </AppMenuList>
                  </AppMenuListExpandableItem>
                )}
                {community?.options?.crowdicityAsIDPForSalesforce &&
                  idp?.region_idp_url &&
                  idp?.sp?.salesforce?.entityid && (
                    <AppMenuListItem hasDivider icon={<SupportTicketSignalOutline size={16} aria-hidden="true" />}>
                      <a
                        target="_blank"
                        href={`${idp.region_idp_url}/saml2/idp/SSOService.php?spentityid=${idp.sp.salesforce.entityid}&hid=${community.id}`}
                      >
                        <FormattedMessage id="submit" defaultMessage="Submit a ticket" />
                      </a>
                    </AppMenuListItem>
                  )}
                {isLoggedIn && (
                  <AppMenuListItem hasDivider icon={<LogoutIcon size={16} aria-hidden="true" />}>
                    <a href="/user/logout">
                      <FormattedMessage id="logOut" defaultMessage="Log Out" />
                    </a>
                  </AppMenuListItem>
                )}
              </AppMenuList>
            )}
          />
        </Styled.ButtonWrapper>
      </AppBarButtonGroup>
    </>
  );
};
