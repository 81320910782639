import { createRoot } from 'react-dom/client';
import { ChallengeManagement } from './features/challenge-management/ChallengeManagement';
import { ChallengeProvider } from './features/challenge-management/context/Challenge';
import { Profile } from './features/profile/Profile';
import { Wrapper } from './common/wrapper';
import { type AppProps } from './common/interfaces';
import { People } from './features/people/People';
import { Leaderboard } from './features/leaderboard/Leaderboard';
import { Analytics } from './features/analytics/Analytics';
import { ModeratorActionPanel } from './features/moderator-action/ModeratorActionPanel';
import { ScoreReset } from './features/score-reset/ScoreReset';
import { type ScoreResetView } from './features/score-reset/enums/ScoreResetView';
import { UserScore } from './features/user-score/UserScore';
import { SubscribeButton } from './features/subscribe/SubscribeButton';
import { type SubscribeThemesData } from './common/interfaces/SubscribeButton';

interface ChallengeManagementProps extends AppProps {
  readonly challengeId?: string;
}

export interface AnalyticsProps extends AppProps {
  readonly page: string;
}

export interface ModeratorActionProps extends AppProps {
  readonly postId: string;
  readonly originalLocale?: string;
}

export interface ScoreResetProps extends AppProps {
  readonly scoreResetView: ScoreResetView;
  readonly scoreResetUserId: string;
}

export interface UserScoreProps extends AppProps {
  readonly userScoreId: string;
}

export interface SubscribeButtonProps extends AppProps {
  readonly data: {
    themes: SubscribeThemesData[];
    isSubscribedToEntireChallenge: boolean;
    isSubscribedToTopLevel: boolean;
  };
  readonly isModeratorSubscribe: boolean;
  readonly challengeId: string;
}

declare global {
  interface Window {
    COMPONENTS: {
      challengeManagementApp: (props: ChallengeManagementProps) => void;
      profileApp: (props: AppProps) => void;
      peopleApp: (props: AppProps) => void;
      analyticsApp: (props: AnalyticsProps, targetNode: Element | DocumentFragment) => void;
      leaderboardApp: (props: AppProps) => void;
      scoreResetApp: (props: ScoreResetProps, targetNode: Element | DocumentFragment) => void;
      userScoreApp: (props: UserScoreProps, targetNode: Element | DocumentFragment) => void;
      moderatorActionApp: (props: ModeratorActionProps, targetNode: Element | DocumentFragment) => void;
      subscribeButtonApp: (props: SubscribeButtonProps, targetNode: Element | DocumentFragment) => void;
    };
  }
}

export const ChallengeManagementApp = (props: ChallengeManagementProps) => {
  const challengeId = String(props.challengeId);

  return (
    <Wrapper {...props}>
      <ChallengeProvider id={challengeId}>
        <ChallengeManagement />
      </ChallengeProvider>
    </Wrapper>
  );
};

export const ProfileApp = (props: AppProps) => (
  <Wrapper {...props}>
    <Profile />
  </Wrapper>
);

export const PeopleApp = (props: AppProps) => (
  <Wrapper {...props}>
    <People />
  </Wrapper>
);

export const AnalyticsApp = (props: AnalyticsProps) => (
  <Wrapper {...props}>
    <Analytics {...props} />
  </Wrapper>
);

export const LeaderboardApp = (props: AppProps) => (
  <Wrapper {...props}>
    <Leaderboard />
  </Wrapper>
);

export const ModeratorActionApp = (props: ModeratorActionProps) => (
  <Wrapper {...props}>
    <ModeratorActionPanel {...props} />
  </Wrapper>
);

export const ScoreResetApp = (props: ScoreResetProps) => (
  <Wrapper {...props}>
    <ScoreReset {...props} />
  </Wrapper>
);

export const UserScoreApp = (props: UserScoreProps) => (
  <Wrapper {...props}>
    <UserScore {...props} />
  </Wrapper>
);

export const SubscribeButtonApp = (props: SubscribeButtonProps) => (
  <Wrapper {...props}>
    <SubscribeButton {...props} />
  </Wrapper>
);

const domNode = document.createElement('div');
document.querySelector('#main')?.appendChild(domNode);
const root = createRoot(domNode);

window.COMPONENTS = {
  challengeManagementApp: (props: ChallengeManagementProps) => {
    root.render(<ChallengeManagementApp {...props} />);
  },
  profileApp: (props: AppProps) => {
    root.render(<ProfileApp {...props} />);
  },
  peopleApp: (props: AppProps) => {
    root.render(<PeopleApp {...props} />);
  },
  analyticsApp: (props: AnalyticsProps, targetNode) => {
    const targetRoot = createRoot(targetNode);
    targetRoot.render(<AnalyticsApp {...props} />);
  },
  leaderboardApp: (props: AppProps) => {
    root.render(<LeaderboardApp {...props} />);
  },
  scoreResetApp: (props: ScoreResetProps, targetNode) => {
    const targetRoot = createRoot(targetNode);
    targetRoot.render(<ScoreResetApp {...props} />);
  },
  userScoreApp: (props: UserScoreProps, targetNode) => {
    const targetRoot = createRoot(targetNode);
    targetRoot.render(<UserScoreApp {...props} />);
  },
  moderatorActionApp: (props: ModeratorActionProps, targetNode) => {
    const targetRoot = createRoot(targetNode);
    targetRoot.render(<ModeratorActionApp {...props} />);
  },
  subscribeButtonApp: (props: SubscribeButtonProps, targetNode) => {
    const targetRoot = createRoot(targetNode);
    targetRoot.render(<SubscribeButtonApp {...props} />);
  }
};
