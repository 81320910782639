import { gql } from '@apollo/client';

export const NOTIFICATION_FOLLOW = gql`
  mutation notificationFollow($input: NotificationFollowInput!) {
    follow(input: $input) {
      success
      message
    }
  }
`;

export const NOTIFICATION_UNFOLLOW = gql`
  mutation notificationUnfollow($input: NotificationFollowInput!) {
    unfollow(input: $input) {
      success
      message
    }
  }
`;

export const CHALLENGE_MODERATOR_FOLLOW_SAVE = gql`
  mutation saveChallengeModeratorFollow($challengeId: String!, $themeIds: [String!]!) {
    saveChallengeModeratorFollow(challengeId: $challengeId, themeIds: $themeIds) {
      message
      success
      code
    }
  }
`;
