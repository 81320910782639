/* eslint-disable */

// This file was automatically generated by graphql-codegen. Do not edit this directly.

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  /** Int scalar type with with minimum limit. */
  IntMinValue_0: { input: any; output: any; }
  /** String scalar type with maximum length limit. */
  StringLength_Max11: { input: any; output: any; }
  /** String scalar type with maximum length limit. */
  StringLength_Max50: { input: any; output: any; }
  /** String scalar type with maximum length limit. */
  StringLength_Max140: { input: any; output: any; }
  /** String scalar type with maximum length limit. */
  StringLength_Max250: { input: any; output: any; }
  /** String scalar type with defined range of length. */
  StringLength_Max250_Min1: { input: any; output: any; }
  /** String scalar type with defined range of length. */
  StringLength_Max250_Min3: { input: any; output: any; }
  /** String scalar type with defined range of length. */
  StringLength_Max250_Min6: { input: any; output: any; }
  /** String scalar type with defined range of length. */
  StringLength_Max255_Min1: { input: any; output: any; }
  /** String scalar type with maximum length limit. */
  StringLength_Max1000: { input: any; output: any; }
  /** String scalar type with defined range of length. */
  StringLength_Max1000_Min1: { input: any; output: any; }
};

export type Access = {
  __typename?: 'Access';
  canModerate?: Maybe<Scalars['Int']['output']>;
  communityId?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  for?: Maybe<Scalars['String']['output']>;
  forId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
  typeId?: Maybe<Scalars['ID']['output']>;
};

export type AccessComments = {
  __typename?: 'AccessComments';
  anonymousEnabled: Scalars['Boolean']['output'];
  attachmentsEnabled: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
};

export type AccessCommentsInput = {
  anonymousEnabled: Scalars['Boolean']['input'];
  attachmentsEnabled: Scalars['Boolean']['input'];
  enabled: Scalars['Boolean']['input'];
};

export type AccessInput = {
  canModerate: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['String']['input'];
};

export type AccessOption = {
  __typename?: 'AccessOption';
  groups?: Maybe<Array<UserGroupWithModerationStatus>>;
  options: ChallengeVisibility;
  users?: Maybe<Array<UserWithModerationStatus>>;
};

export type AccessOptionInput = {
  groups: Array<AccessInput>;
  options?: InputMaybe<ChallengeVisibilityInput>;
  users: Array<AccessInput>;
};

export enum AccountProviderType {
  AZURE = 'AZURE',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  GOOGLEP = 'GOOGLEP',
  LINKEDIN = 'LINKEDIN',
  MEC = 'MEC',
  SAML2 = 'SAML2',
  SLACK = 'SLACK',
  SSOPROXY = 'SSOPROXY',
  TEAMS = 'TEAMS',
  TWITTER = 'TWITTER',
  YAMMER = 'YAMMER'
}

export type AnonymousUser = {
  __typename?: 'AnonymousUser';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  profileImageId: Scalars['String']['output'];
  screenName: Scalars['String']['output'];
};

export type ApiClient = {
  __typename?: 'ApiClient';
  authEndPoint?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  password?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['ID']['output']>;
  secret?: Maybe<Scalars['String']['output']>;
  status: ApiClientStatusType;
  userId: Scalars['ID']['output'];
};

export type ApiClientInput = {
  authEndPoint?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  regeneratePassword: Scalars['Boolean']['input'];
  requestId: Scalars['String']['input'];
};

export enum ApiClientStatusType {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  REMOVED = 'REMOVED'
}

export type ApiRequest = {
  __typename?: 'ApiRequest';
  applied: Scalars['DateTime']['output'];
  client?: Maybe<ApiClient>;
  communityId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: ApiRequestStatusType;
  userId: Scalars['String']['output'];
};

export type ApiRequestInput = {
  description: Scalars['String']['input'];
};

export enum ApiRequestStatusType {
  APPLIED = 'APPLIED',
  APPROVED = 'APPROVED',
  REFUSED = 'REFUSED'
}

export type AppSwitcherOptions = {
  __typename?: 'AppSwitcherOptions';
  icon?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AuditLog = {
  __typename?: 'AuditLog';
  action: Scalars['String']['output'];
  communityId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  destinationIP?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  processName?: Maybe<Scalars['String']['output']>;
  requestUrl?: Maybe<Scalars['String']['output']>;
  routeId?: Maybe<Scalars['String']['output']>;
  service: Scalars['String']['output'];
  sourceIP?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type Badge = {
  __typename?: 'Badge';
  actionType?: Maybe<Scalars['String']['output']>;
  auto?: Maybe<Scalars['Boolean']['output']>;
  badges?: Maybe<Array<Badge>>;
  communityId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creatorId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageFile?: Maybe<File>;
  imageId?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ruleAmount?: Maybe<Scalars['Int']['output']>;
  ruleChallengeId?: Maybe<Scalars['ID']['output']>;
  ruleMilestoneId?: Maybe<Scalars['ID']['output']>;
  rulePhaseId?: Maybe<Scalars['ID']['output']>;
  score: Scalars['Int']['output'];
  sourceType?: Maybe<Scalars['String']['output']>;
  stockImage?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BadgeWithCountType = {
  __typename?: 'BadgeWithCountType';
  actionType?: Maybe<Scalars['String']['output']>;
  auto?: Maybe<Scalars['Boolean']['output']>;
  badges?: Maybe<Array<Badge>>;
  communityId?: Maybe<Scalars['ID']['output']>;
  count: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creatorId?: Maybe<Scalars['ID']['output']>;
  dateAwarded: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageFile?: Maybe<File>;
  imageId?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  ruleAmount?: Maybe<Scalars['Int']['output']>;
  ruleChallengeId?: Maybe<Scalars['ID']['output']>;
  ruleMilestoneId?: Maybe<Scalars['ID']['output']>;
  rulePhaseId?: Maybe<Scalars['ID']['output']>;
  score: Scalars['Int']['output'];
  sourceType?: Maybe<Scalars['String']['output']>;
  stockImage?: Maybe<Scalars['String']['output']>;
  totalScore: Scalars['Int']['output'];
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type BlogPost = {
  __typename?: 'BlogPost';
  communityId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<IdeaFiles>;
  id: Scalars['ID']['output'];
  stats?: Maybe<BlogPostStats>;
  text?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Theme>;
  title?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type BlogPostFollow = {
  __typename?: 'BlogPostFollow';
  blogPostId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type BlogPostStats = {
  __typename?: 'BlogPostStats';
  comments: Scalars['Int']['output'];
  followers: Scalars['Int']['output'];
  views?: Maybe<Scalars['Int']['output']>;
  votes?: Maybe<Scalars['Int']['output']>;
};

export type Challenge = {
  __typename?: 'Challenge';
  access?: Maybe<AccessOption>;
  archived?: Maybe<Scalars['Int']['output']>;
  categories?: Maybe<Array<GlobalCategoryWithAssignedField>>;
  communityId: Scalars['String']['output'];
  config: ChallengeConfig;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  customHeadings?: Maybe<Scalars['String']['output']>;
  daysLimit?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  fileId?: Maybe<Scalars['ID']['output']>;
  files?: Maybe<ChallengeFiles>;
  headerFileId?: Maybe<Scalars['ID']['output']>;
  headings: ChallengeCustomHeadings;
  id: Scalars['ID']['output'];
  ideas?: Maybe<Array<Idea>>;
  ideasHaveVotes?: Maybe<Scalars['Boolean']['output']>;
  isFunnel?: Maybe<Scalars['Boolean']['output']>;
  isMilestone?: Maybe<Scalars['Boolean']['output']>;
  isPrivateSubmission?: Maybe<Scalars['Boolean']['output']>;
  isUserCreated?: Maybe<Scalars['Boolean']['output']>;
  lastProcessedPhase?: Maybe<Scalars['Int']['output']>;
  lastScorecardUpdate?: Maybe<Scalars['DateTime']['output']>;
  longDescription?: Maybe<Scalars['String']['output']>;
  milestone?: Maybe<ChallengeMilestone>;
  milestones?: Maybe<Array<Milestone>>;
  milestonesResetTime?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  phaseStats?: Maybe<Scalars['String']['output']>;
  phases?: Maybe<Array<Phase>>;
  questions?: Maybe<Array<ChallengeQuestion>>;
  scorecardLabels?: Maybe<Array<ScorecardLabel>>;
  scorecards?: Maybe<Array<Scorecard>>;
  showInCarousels?: Maybe<Scalars['Boolean']['output']>;
  stats?: Maybe<ChallengeStats>;
  subtitle?: Maybe<Scalars['String']['output']>;
  themes: Array<Theme>;
  title: Scalars['String']['output'];
  translations?: Maybe<Array<Translation>>;
  type?: Maybe<Scalars['Int']['output']>;
  voting?: Maybe<Voting>;
  walletLastUpdate?: Maybe<Scalars['DateTime']['output']>;
};

export type ChallengeCategory = {
  __typename?: 'ChallengeCategory';
  challengeId: Scalars['ID']['output'];
  globalCategoryId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export type ChallengeConfig = {
  __typename?: 'ChallengeConfig';
  hideUploadFiles?: Maybe<Scalars['Boolean']['output']>;
  ideaDescriptionCharacterLimit?: Maybe<Scalars['Int']['output']>;
  ideaDescriptionType?: Maybe<Scalars['String']['output']>;
  showInCarousels?: Maybe<Scalars['Boolean']['output']>;
  sorting: ChallengeSorting;
  textThemeName?: Maybe<Scalars['String']['output']>;
  themeRequired: Scalars['Boolean']['output'];
};

export type ChallengeConfigInput = {
  hideUploadFiles?: InputMaybe<Scalars['Boolean']['input']>;
  ideaDescriptionCharacterLimit?: InputMaybe<Scalars['Int']['input']>;
  ideaDescriptionType?: InputMaybe<Scalars['String']['input']>;
  showInCarousels?: InputMaybe<Scalars['Boolean']['input']>;
  sorting: ChallengeSortingInput;
  textThemeName?: InputMaybe<Scalars['String']['input']>;
  themeRequired: Scalars['Boolean']['input'];
};

export type ChallengeCustomHeadings = {
  __typename?: 'ChallengeCustomHeadings';
  ideaDescriptionHeading: Scalars['String']['output'];
  ideaDescriptionInfoHeading: Scalars['String']['output'];
  ideaFilesHeading: Scalars['String']['output'];
  ideaTitleHeading: Scalars['String']['output'];
  ideaTitleInfoHeading: Scalars['String']['output'];
  themeSelectHeading: Scalars['String']['output'];
};

export type ChallengeCustomHeadingsInput = {
  ideaDescriptionHeading?: InputMaybe<Scalars['StringLength_Max1000_Min1']['input']>;
  ideaDescriptionInfoHeading?: InputMaybe<Scalars['StringLength_Max1000']['input']>;
  ideaFilesHeading?: InputMaybe<Scalars['StringLength_Max1000_Min1']['input']>;
  ideaTitleHeading?: InputMaybe<Scalars['StringLength_Max1000_Min1']['input']>;
  ideaTitleInfoHeading?: InputMaybe<Scalars['StringLength_Max1000']['input']>;
  themeSelectHeading?: InputMaybe<Scalars['StringLength_Max1000_Min1']['input']>;
};

export type ChallengeFiles = {
  __typename?: 'ChallengeFiles';
  attachments?: Maybe<Array<File>>;
  header?: Maybe<File>;
  list?: Maybe<File>;
};

export type ChallengeFilesInput = {
  attachments?: InputMaybe<Array<FileInput>>;
  header?: InputMaybe<FileInput>;
  list?: InputMaybe<FileInput>;
};

export type ChallengeFollow = {
  __typename?: 'ChallengeFollow';
  challengeId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  include?: Maybe<Scalars['String']['output']>;
  lastUpdate?: Maybe<Scalars['DateTime']['output']>;
  specific?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export enum ChallengeFollowIncludeType {
  ALL = 'ALL',
  SPECIFIC = 'SPECIFIC',
  THEMES_ONLY = 'THEMES_ONLY'
}

export enum ChallengeFollowType {
  DAILY = 'DAILY',
  INSTANT = 'INSTANT',
  WEEKLY = 'WEEKLY'
}

export type ChallengeInput = {
  access?: InputMaybe<AccessOptionInput>;
  archived: Scalars['Int']['input'];
  categories?: InputMaybe<Array<GlobalCategoryWithAssignedFieldInput>>;
  config: ChallengeConfigInput;
  daysLimit?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<ChallengeFilesInput>;
  headings: ChallengeCustomHeadingsInput;
  id: Scalars['String']['input'];
  isFunnel: Scalars['Boolean']['input'];
  isMilestone: Scalars['Boolean']['input'];
  isPrivateSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  milestone?: InputMaybe<MilestoneInput>;
  options?: InputMaybe<Scalars['String']['input']>;
  phases?: InputMaybe<Array<PhaseInput>>;
  questions?: InputMaybe<Array<ChallengeQuestionInput>>;
  subtitle?: InputMaybe<Scalars['StringLength_Max250']['input']>;
  template?: InputMaybe<ChallengeTemplateInput>;
  themes?: InputMaybe<Array<ThemeInput>>;
  title?: InputMaybe<Scalars['StringLength_Max250_Min3']['input']>;
  translations?: InputMaybe<Array<TranslationInput>>;
  voting?: InputMaybe<VotingInput>;
};

export type ChallengeLog = {
  __typename?: 'ChallengeLog';
  action?: Maybe<Scalars['String']['output']>;
  actionFromTable?: Maybe<Scalars['String']['output']>;
  challengeId?: Maybe<Scalars['Int']['output']>;
  communityId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  phaseId?: Maybe<Scalars['Int']['output']>;
  postId?: Maybe<Scalars['Int']['output']>;
  processed?: Maybe<Scalars['Int']['output']>;
  processedDate: Scalars['DateTime']['output'];
  voteId?: Maybe<Scalars['Int']['output']>;
};

export type ChallengeMilestone = {
  __typename?: 'ChallengeMilestone';
  completeMessageToAuthors?: Maybe<Scalars['String']['output']>;
  completeMessageToUsers?: Maybe<Scalars['String']['output']>;
  daysLimit?: Maybe<Scalars['String']['output']>;
  milestoneRejectedIdeaIds?: Maybe<Array<Scalars['String']['output']>>;
  milestones?: Maybe<Array<Milestone>>;
  timeLimitEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export enum ChallengeModeratorFollowIncludeType {
  all = 'all',
  specific = 'specific',
  themesOnly = 'themesOnly'
}

export enum ChallengePublicationStatus {
  ALL = 'ALL',
  ARCHIVED = 'ARCHIVED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED'
}

export type ChallengeQuestion = {
  __typename?: 'ChallengeQuestion';
  challengeId?: Maybe<Scalars['ID']['output']>;
  characterLimit?: Maybe<Scalars['Int']['output']>;
  communityId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  inCreation?: Maybe<Scalars['Boolean']['output']>;
  inPhase?: Maybe<Scalars['Boolean']['output']>;
  milestoneId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<ChallengeQuestionOption>>;
  order?: Maybe<Scalars['Int']['output']>;
  phaseList?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  settings?: Maybe<Scalars['String']['output']>;
  translations?: Maybe<Array<Translation>>;
  type: Scalars['String']['output'];
};

export type ChallengeQuestionInput = {
  characterLimit?: InputMaybe<Scalars['IntMinValue_0']['input']>;
  description?: InputMaybe<Scalars['StringLength_Max1000']['input']>;
  hidden: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  inPhase: Scalars['Boolean']['input'];
  milestoneId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['StringLength_Max255_Min1']['input']>;
  options?: InputMaybe<Array<ChallengeQuestionOptionInput>>;
  order: Scalars['Int']['input'];
  required: Scalars['Boolean']['input'];
  translations?: InputMaybe<Array<TranslationInput>>;
  type: Scalars['String']['input'];
};

export type ChallengeQuestionOption = {
  __typename?: 'ChallengeQuestionOption';
  challengeQuestionId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  translations?: Maybe<Array<Translation>>;
};

export type ChallengeQuestionOptionInput = {
  challengeQuestionId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['StringLength_Max250']['input']>;
  translations?: InputMaybe<Array<TranslationInput>>;
};

export type ChallengeSorting = {
  __typename?: 'ChallengeSorting';
  default: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
};

export type ChallengeSortingInput = {
  default: Scalars['String']['input'];
  options: Array<Scalars['String']['input']>;
};

export type ChallengeStats = {
  __typename?: 'ChallengeStats';
  comments?: Maybe<Scalars['Int']['output']>;
  commentsPlusChildren?: Maybe<Scalars['Int']['output']>;
  ideas?: Maybe<Scalars['Int']['output']>;
  ideasPlusChildren?: Maybe<Scalars['Int']['output']>;
  subscribers?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Scalars['Int']['output']>;
  usersPlusChildren?: Maybe<Scalars['Int']['output']>;
  votes?: Maybe<Scalars['Int']['output']>;
  votesPlusChildren?: Maybe<Scalars['Int']['output']>;
};

export type ChallengeTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['StringLength_Max250_Min1']['input']>;
  userId: Scalars['String']['input'];
};

export type ChallengeVisibility = {
  __typename?: 'ChallengeVisibility';
  comments: AccessComments;
  copyUrlEnabled?: Maybe<Scalars['Boolean']['output']>;
  ideaEditLock: IdeaEditLock;
  ideaSubmissionEnabled: Scalars['Boolean']['output'];
  ideaSubmissionsType: IdeaSubmissionsType;
  ideasVisible: Scalars['Boolean']['output'];
  phaseAutoAdvancement?: Maybe<Scalars['Boolean']['output']>;
  phaseResetComments?: Maybe<Scalars['Boolean']['output']>;
  phaseResetVotes?: Maybe<Scalars['Boolean']['output']>;
  privateSubmissionEnabled: Scalars['Boolean']['output'];
  shareFacebookEnabled?: Maybe<Scalars['Boolean']['output']>;
  shareLinkedInEnabled?: Maybe<Scalars['Boolean']['output']>;
  shareTwitterEnabled?: Maybe<Scalars['Boolean']['output']>;
  showClosedEnabled: Scalars['Boolean']['output'];
  voteOnCommentsEnabled: Scalars['Boolean']['output'];
  voteOnIdeasEnabled: Scalars['Boolean']['output'];
};

export type ChallengeVisibilityInput = {
  comments: AccessCommentsInput;
  copyUrlEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  ideaEditLock: IdeaEditLockInput;
  ideaSubmissionEnabled: Scalars['Boolean']['input'];
  ideaSubmissionsType: IdeaSubmissionsType;
  ideasVisible: Scalars['Boolean']['input'];
  phaseAutoAdvancement?: InputMaybe<Scalars['Boolean']['input']>;
  phaseResetComments?: InputMaybe<Scalars['Boolean']['input']>;
  phaseResetVotes?: InputMaybe<Scalars['Boolean']['input']>;
  privateSubmissionEnabled: Scalars['Boolean']['input'];
  shareFacebookEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  shareLinkedInEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  shareTwitterEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  showClosedEnabled: Scalars['Boolean']['input'];
  voteOnCommentsEnabled: Scalars['Boolean']['input'];
  voteOnIdeasEnabled: Scalars['Boolean']['input'];
};

export type ColumnSortType = {
  column: Scalars['String']['input'];
  direction?: InputMaybe<Scalars['String']['input']>;
};

export type Comment = {
  __typename?: 'Comment';
  adoptionStatus?: Maybe<Scalars['Int']['output']>;
  challengeId: Scalars['Int']['output'];
  communityId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentMilestone?: Maybe<Scalars['ID']['output']>;
  hasReports: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isModeratorComment?: Maybe<Scalars['Int']['output']>;
  moderationStatus?: Maybe<Scalars['Int']['output']>;
  parentId: Scalars['ID']['output'];
  topParentId: Scalars['ID']['output'];
  type?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['Int']['output'];
};

export type Community = {
  __typename?: 'Community';
  apiEnabled: Scalars['Boolean']['output'];
  closed?: Maybe<Scalars['Boolean']['output']>;
  colors?: Maybe<CommunityDesign>;
  countries?: Maybe<Array<Scalars['String']['output']>>;
  defaultAccess?: Maybe<DefaultAccess>;
  hideUnModerated: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  logoFile?: Maybe<File>;
  logoId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  options?: Maybe<CommunityOptions>;
  url: Scalars['String']['output'];
  userFields?: Maybe<Array<UserField>>;
};

export type CommunityDesign = {
  __typename?: 'CommunityDesign';
  backgroundIsAttached?: Maybe<Scalars['Boolean']['output']>;
  backgroundIsRepeated?: Maybe<Scalars['Boolean']['output']>;
  backgroundPosition?: Maybe<Scalars['String']['output']>;
  banner?: Maybe<Scalars['String']['output']>;
  bannerHeight?: Maybe<Scalars['Int']['output']>;
  bg?: Maybe<Scalars['String']['output']>;
  button?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  userNavBg?: Maybe<Scalars['String']['output']>;
  voteBar?: Maybe<Scalars['String']['output']>;
};

export type CommunityLanguage = {
  __typename?: 'CommunityLanguage';
  iso: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CommunityOptions = {
  __typename?: 'CommunityOptions';
  OpenIdProvider?: Maybe<Scalars['String']['output']>;
  allowAzureLogin?: Maybe<Scalars['Boolean']['output']>;
  allowCrowdicityLogin?: Maybe<Scalars['Boolean']['output']>;
  allowLanguageChoice?: Maybe<Scalars['Boolean']['output']>;
  allowMessaging?: Maybe<Scalars['Boolean']['output']>;
  allowSAML2Login?: Maybe<Scalars['Boolean']['output']>;
  allowSocialMediaLinksInProfile?: Maybe<Scalars['Boolean']['output']>;
  allowSocialMediaLogin?: Maybe<Scalars['Boolean']['output']>;
  allowedLanguages?: Maybe<Array<CommunityLanguage>>;
  anonymousSubmissionsEnabled?: Maybe<Scalars['Boolean']['output']>;
  apiEnabled?: Maybe<Scalars['Boolean']['output']>;
  appSwitcher?: Maybe<Array<AppSwitcherOptions>>;
  crowdicityAsIDPForSalesforce?: Maybe<Scalars['Boolean']['output']>;
  enableSlackIntegration?: Maybe<Scalars['Boolean']['output']>;
  enableTeamsIntegration?: Maybe<Scalars['Boolean']['output']>;
  facebookAllowFollow?: Maybe<Scalars['Boolean']['output']>;
  facebookName?: Maybe<Scalars['String']['output']>;
  livingLens?: Maybe<LivingLens>;
  showUserScores?: Maybe<Scalars['Boolean']['output']>;
  translationLanguages?: Maybe<Array<Scalars['String']['output']>>;
  twitterAllowFollow?: Maybe<Scalars['Boolean']['output']>;
  twitterName?: Maybe<Scalars['String']['output']>;
  youtubeAllowFollow?: Maybe<Scalars['Boolean']['output']>;
  youtubeName?: Maybe<Scalars['String']['output']>;
};

export type CreateChallengeInput = {
  archived?: InputMaybe<Scalars['Int']['input']>;
  isGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};

export type DataLog = {
  __typename?: 'DataLog';
  changeType?: Maybe<Scalars['String']['output']>;
  communityId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  newData?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  objectId?: Maybe<Scalars['Int']['output']>;
  objectType?: Maybe<Scalars['String']['output']>;
  oldData?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type DefaultAccess = {
  __typename?: 'DefaultAccess';
  groups: Array<UserGroupWithModerationStatus>;
  users: Array<UserWithModerationStatus>;
};

export type Error = {
  __typename?: 'Error';
  communityId?: Maybe<Scalars['Int']['output']>;
  context?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  level?: Maybe<Scalars['Int']['output']>;
  line?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type File = {
  __typename?: 'File';
  avatarReady?: Maybe<Scalars['Int']['output']>;
  badgeReady?: Maybe<Scalars['Int']['output']>;
  challengeReady?: Maybe<Scalars['Int']['output']>;
  checksum?: Maybe<Scalars['String']['output']>;
  cloudDate?: Maybe<Scalars['DateTime']['output']>;
  cloudinaryDate?: Maybe<Scalars['DateTime']['output']>;
  cloudinaryEncoded?: Maybe<Scalars['Int']['output']>;
  communityId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  extension: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logoReady?: Maybe<Scalars['Int']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  origin?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  thumbnailCreated?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  virusScanStatus?: Maybe<Scalars['Int']['output']>;
};

export type FileInput = {
  extension?: InputMaybe<Scalars['String']['input']>;
  hash: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type FileLink = {
  __typename?: 'FileLink';
  fileId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  itemType?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type FollowChallengeInput = {
  include?: InputMaybe<ChallengeFollowIncludeType>;
  lastUpdate?: InputMaybe<Scalars['DateTime']['input']>;
  specific?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ChallengeFollowType>;
};

export enum FollowType {
  CHALLENGE = 'CHALLENGE',
  IDEA = 'IDEA',
  USER = 'USER'
}

export type GlobalCategory = {
  __typename?: 'GlobalCategory';
  accessMode?: Maybe<Scalars['String']['output']>;
  communityId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  status?: Maybe<Scalars['Int']['output']>;
};

export type GlobalCategoryWithAssignedField = {
  __typename?: 'GlobalCategoryWithAssignedField';
  assigned: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export type GlobalCategoryWithAssignedFieldInput = {
  assigned: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['StringLength_Max250_Min1']['input']>;
  order: Scalars['Int']['input'];
};

export type GlobalTemplate = {
  __typename?: 'GlobalTemplate';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  template: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Idea = {
  __typename?: 'Idea';
  adoptionStatus?: Maybe<Scalars['Int']['output']>;
  challenge?: Maybe<Challenge>;
  challengeId: Scalars['ID']['output'];
  commentCount?: Maybe<Scalars['Int']['output']>;
  communityId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentMilestone?: Maybe<Scalars['ID']['output']>;
  currentPhase?: Maybe<Scalars['ID']['output']>;
  endPhase?: Maybe<Scalars['ID']['output']>;
  files?: Maybe<IdeaFiles>;
  hasReports?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isAnonymous?: Maybe<Scalars['Int']['output']>;
  lastReachedMilestone?: Maybe<Scalars['ID']['output']>;
  moderationStatus?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  startPhase?: Maybe<Scalars['ID']['output']>;
  stats?: Maybe<IdeaStats>;
  text?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Theme>;
  title?: Maybe<Scalars['String']['output']>;
  topParentId?: Maybe<Scalars['ID']['output']>;
  user?: Maybe<IdeaUser>;
  userId: Scalars['ID']['output'];
  views?: Maybe<Scalars['Int']['output']>;
  voteCount?: Maybe<Scalars['Int']['output']>;
};

export type IdeaEditLock = {
  __typename?: 'IdeaEditLock';
  enabled: Scalars['Boolean']['output'];
  graceMinutes?: Maybe<Scalars['Int']['output']>;
};

export type IdeaEditLockInput = {
  enabled: Scalars['Boolean']['input'];
  graceMinutes?: InputMaybe<Scalars['Int']['input']>;
};

export type IdeaFiles = {
  __typename?: 'IdeaFiles';
  images?: Maybe<Array<File>>;
};

export type IdeaFollow = {
  __typename?: 'IdeaFollow';
  id: Scalars['ID']['output'];
  ideaId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type IdeaStats = {
  __typename?: 'IdeaStats';
  comments: Scalars['Int']['output'];
  followers: Scalars['Int']['output'];
  views?: Maybe<Scalars['Int']['output']>;
  votes?: Maybe<Scalars['Int']['output']>;
};

export type IdeaStatus = {
  __typename?: 'IdeaStatus';
  color: Scalars['String']['output'];
  communityId: Scalars['Int']['output'];
  defaultNameOverride?: Maybe<Scalars['Int']['output']>;
  enabled?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  textColor: Scalars['String']['output'];
};

export type IdeaStatusLink = {
  __typename?: 'IdeaStatusLink';
  date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  ideaId?: Maybe<Scalars['Int']['output']>;
  ideaStatusId?: Maybe<Scalars['Int']['output']>;
};

export type IdeaStatusesResultType = {
  __typename?: 'IdeaStatusesResultType';
  ideaCount: Scalars['Int']['output'];
  statusId: Scalars['Int']['output'];
  statusName: Scalars['String']['output'];
};

export enum IdeaSubmissionsType {
  ANONYMOUS_ENFORCED = 'ANONYMOUS_ENFORCED',
  ANONYMOUS_OPTIONAL = 'ANONYMOUS_OPTIONAL',
  JOINT = 'JOINT',
  STANDARD = 'STANDARD'
}

export type IdeaTagsResultType = {
  __typename?: 'IdeaTagsResultType';
  ideaCount: Scalars['Int']['output'];
  tagId: Scalars['Int']['output'];
  tagName: Scalars['String']['output'];
};

export type IdeaUser = AnonymousUser | User;

export type IdeasAndCount = {
  __typename?: 'IdeasAndCount';
  count: Scalars['Float']['output'];
  rows: Array<Idea>;
};

export type Language = {
  __typename?: 'Language';
  language: Scalars['String']['output'];
};

export type LivingLens = {
  __typename?: 'LivingLens';
  enabled: Scalars['Boolean']['output'];
};

export type Milestone = {
  __typename?: 'Milestone';
  access: MilestoneOption;
  allowComments?: Maybe<Scalars['Boolean']['output']>;
  approachEmailMessageToModerators?: Maybe<Scalars['String']['output']>;
  approachEmailMessageToVoters?: Maybe<Scalars['String']['output']>;
  approachMessageToAuthors?: Maybe<Scalars['String']['output']>;
  approachMessageToUsers?: Maybe<Scalars['String']['output']>;
  challengeId?: Maybe<Scalars['ID']['output']>;
  condition?: Maybe<MilestoneCondition>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ideas?: Maybe<Array<Idea>>;
  ideasHaveVotes?: Maybe<Scalars['Boolean']['output']>;
  isPrivateSubmission?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  minimumVotes?: Maybe<Scalars['Int']['output']>;
  moderators?: Maybe<UsersAndGroups>;
  name?: Maybe<Scalars['String']['output']>;
  notifyVoters?: Maybe<Scalars['Int']['output']>;
  questions?: Maybe<Array<ChallengeQuestion>>;
  reachedEmailMessageToAuthors?: Maybe<Scalars['String']['output']>;
  resetComments?: Maybe<Scalars['Int']['output']>;
  resetVotes?: Maybe<Scalars['Boolean']['output']>;
  scorecards?: Maybe<Array<Scorecard>>;
  showUsers?: Maybe<Scalars['Int']['output']>;
  target?: Maybe<Scalars['Int']['output']>;
  translations?: Maybe<Array<Translation>>;
  type?: Maybe<MilestoneType>;
  voters?: Maybe<UsersAndGroups>;
  voting?: Maybe<Voting>;
};


export type MilestoneIdeasArgs = {
  filters?: InputMaybe<UserSearchFilters>;
};

export type MilestoneCondition = {
  __typename?: 'MilestoneCondition';
  id: Scalars['ID']['output'];
  milestoneId?: Maybe<Scalars['ID']['output']>;
  target?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MilestoneInput = {
  completeMessageToAuthors?: InputMaybe<Scalars['String']['input']>;
  completeMessageToUsers?: InputMaybe<Scalars['String']['input']>;
  milestoneRejectedIdeaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  milestones?: InputMaybe<Array<MilestonesInput>>;
};

export type MilestoneModerator = {
  __typename?: 'MilestoneModerator';
  id: Scalars['ID']['output'];
  milestoneId: Scalars['ID']['output'];
  moderatorId: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type MilestoneModeratorInput = {
  groups?: InputMaybe<Array<ModeratorInput>>;
  users?: InputMaybe<Array<ModeratorInput>>;
};

export type MilestoneOption = {
  __typename?: 'MilestoneOption';
  allowComments: Scalars['Boolean']['output'];
  privateSubmissionEnabled: Scalars['Boolean']['output'];
};

export enum MilestoneType {
  APPROVAL = 'APPROVAL',
  REFINE = 'REFINE',
  SUBMITTED = 'SUBMITTED',
  VOTE = 'VOTE'
}

export type MilestoneVoter = {
  __typename?: 'MilestoneVoter';
  id: Scalars['ID']['output'];
  milestoneId: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
  voterId: Scalars['ID']['output'];
};

export type MilestoneVoterInput = {
  groups?: InputMaybe<Array<VoterInput>>;
  users?: InputMaybe<Array<VoterInput>>;
};

export type MilestonesInput = {
  allowComments: Scalars['Boolean']['input'];
  approachEmailMessageToModerators?: InputMaybe<Scalars['String']['input']>;
  approachEmailMessageToVoters?: InputMaybe<Scalars['String']['input']>;
  approachMessageToAuthors?: InputMaybe<Scalars['String']['input']>;
  approachMessageToUsers?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  isPrivateSubmission: Scalars['Boolean']['input'];
  label?: InputMaybe<Scalars['StringLength_Max50']['input']>;
  minimumVotes?: InputMaybe<Scalars['Int']['input']>;
  moderators?: InputMaybe<MilestoneModeratorInput>;
  name?: InputMaybe<Scalars['StringLength_Max250_Min1']['input']>;
  notifyVoters?: InputMaybe<Scalars['Boolean']['input']>;
  questions?: InputMaybe<Array<ChallengeQuestionInput>>;
  reachedEmailMessageToAuthors?: InputMaybe<Scalars['String']['input']>;
  resetVotes?: InputMaybe<Scalars['Boolean']['input']>;
  target?: InputMaybe<Scalars['Int']['input']>;
  translations?: InputMaybe<Array<TranslationInput>>;
  type?: InputMaybe<Scalars['String']['input']>;
  voters?: InputMaybe<MilestoneVoterInput>;
  voting?: InputMaybe<VotingInput>;
};

export type ModeratorActionBackupTranslation = {
  __typename?: 'ModeratorActionBackupTranslation';
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  objectId: Scalars['Int']['output'];
  objectType: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type ModeratorActionBaseEntity = {
  __typename?: 'ModeratorActionBaseEntity';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ModeratorActionIdeaMovePayload = {
  __typename?: 'ModeratorActionIdeaMovePayload';
  from: ModeratorActionLinkedEntity;
  to: ModeratorActionLinkedEntity;
};

export type ModeratorActionIdeaStatusPayload = {
  __typename?: 'ModeratorActionIdeaStatusPayload';
  added: Array<ModeratorActionBaseEntity>;
  removed: Array<ModeratorActionBaseEntity>;
};

export type ModeratorActionLinkedEntity = {
  __typename?: 'ModeratorActionLinkedEntity';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ModeratorActionLog = {
  __typename?: 'ModeratorActionLog';
  communityId: Scalars['ID']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  moderatorActionId: Scalars['ID']['output'];
  objectData?: Maybe<Scalars['String']['output']>;
  objectId?: Maybe<Scalars['ID']['output']>;
  objectTargetId?: Maybe<Scalars['ID']['output']>;
  postId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type ModeratorActionPayload = ModeratorActionIdeaMovePayload | ModeratorActionIdeaStatusPayload | ModeratorActionSimplePayload;

export type ModeratorActionResult = {
  __typename?: 'ModeratorActionResult';
  items: Array<ModeratorActionResultItem>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ModeratorActionResultItem = {
  __typename?: 'ModeratorActionResultItem';
  actionId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  payload: ModeratorActionPayload;
  user: ModeratorActionUserEntity;
};

export type ModeratorActionSimplePayload = {
  __typename?: 'ModeratorActionSimplePayload';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type ModeratorActionUserEntity = {
  __typename?: 'ModeratorActionUserEntity';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ModeratorInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  milestoneId: Scalars['String']['input'];
  moderatorId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  cloneChallenge: Challenge;
  createChallenge: Challenge;
  deleteTemplate: Scalars['Boolean']['output'];
  deleteUserAccount?: Maybe<SuccessMessageResponseType>;
  follow: SuccessMessageResponseType;
  followUser: SuccessMessageResponseType;
  followers?: Maybe<Array<User>>;
  globalScoreReset: ScoreReset;
  globalScoreUndo: ScoreReset;
  saveApiClient?: Maybe<ApiClient>;
  saveApiRequest?: Maybe<ApiRequest>;
  saveChallengeModeratorFollow: SuccessMessageResponseType;
  saveSocialMedia: SuccessMessageResponseType;
  unfollow: SuccessMessageResponseType;
  unfollowUser: SuccessMessageResponseType;
  updateChallenge: Challenge;
  updateSettings: UserSettingsResponseType;
  updateStealth: SuccessMessageResponseType;
  userScoreReset: ScoreReset;
  userScoreUndo: ScoreReset;
  userScorelogUpdate: ScoreLog;
};


export type MutationCloneChallengeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateChallengeArgs = {
  input: CreateChallengeInput;
};


export type MutationDeleteTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserAccountArgs = {
  id: Scalars['String']['input'];
};


export type MutationFollowArgs = {
  input: NotificationFollowInput;
};


export type MutationFollowUserArgs = {
  targetUser: Scalars['String']['input'];
};


export type MutationGlobalScoreResetArgs = {
  date: Scalars['String']['input'];
  excludeBadges: Scalars['Boolean']['input'];
};


export type MutationGlobalScoreUndoArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationSaveApiClientArgs = {
  input: ApiClientInput;
};


export type MutationSaveApiRequestArgs = {
  input: ApiRequestInput;
};


export type MutationSaveChallengeModeratorFollowArgs = {
  challengeId: Scalars['String']['input'];
  themeIds: Array<Scalars['String']['input']>;
};


export type MutationSaveSocialMediaArgs = {
  input: Array<SocialLinkInput>;
};


export type MutationUnfollowArgs = {
  input: NotificationFollowInput;
};


export type MutationUnfollowUserArgs = {
  targetUser: Scalars['String']['input'];
};


export type MutationUpdateChallengeArgs = {
  input: ChallengeInput;
};


export type MutationUpdateSettingsArgs = {
  input: Array<UserNotificationSettingsInput>;
};


export type MutationUpdateStealthArgs = {
  enabled: Scalars['Boolean']['input'];
};


export type MutationUserScoreResetArgs = {
  date: Scalars['String']['input'];
  excludeBadges: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUserScoreUndoArgs = {
  eventId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUserScorelogUpdateArgs = {
  isRemoved: Scalars['Boolean']['input'];
  scorelogId: Scalars['String']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  challengeId: Scalars['ID']['output'];
  commentId: Scalars['ID']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  ideaId: Scalars['ID']['output'];
};

export enum NotificationActions {
  REPLY = 'REPLY',
  VIEW = 'VIEW'
}

export enum NotificationCategory {
  BLOG_ACTIVITY = 'BLOG_ACTIVITY',
  COMMENT_ACTIVITY = 'COMMENT_ACTIVITY',
  FOLLOWED_USER_ACTIVITY = 'FOLLOWED_USER_ACTIVITY',
  IDEA_ACTIVITY = 'IDEA_ACTIVITY',
  NEW_CHALLENGES_AND_IDEAS = 'NEW_CHALLENGES_AND_IDEAS'
}

export type NotificationEvent = {
  __typename?: 'NotificationEvent';
  actions: Array<NotificationActions>;
  blogPost?: Maybe<BlogPost>;
  blogPostId?: Maybe<Scalars['String']['output']>;
  body: Scalars['String']['output'];
  category?: Maybe<NotificationCategory>;
  challenge?: Maybe<Challenge>;
  challengeId?: Maybe<Scalars['String']['output']>;
  commentId?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  idea?: Maybe<Idea>;
  ideaId?: Maybe<Scalars['String']['output']>;
  isValid: Scalars['Boolean']['output'];
  phaseId?: Maybe<Scalars['String']['output']>;
  reasonId: Scalars['String']['output'];
  reasonType: NotificationReasonType;
  subject: Scalars['String']['output'];
  type: NotificationType;
  user?: Maybe<IdeaUser>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type NotificationFollowInput = {
  challenge?: InputMaybe<FollowChallengeInput>;
  targetId: Scalars['String']['input'];
  type: FollowType;
};

export enum NotificationReasonType {
  CHALLENGE_MODERATOR = 'CHALLENGE_MODERATOR',
  COMMUNITY = 'COMMUNITY',
  FOLLOWED_BLOG = 'FOLLOWED_BLOG',
  FOLLOWED_BLOG_POST = 'FOLLOWED_BLOG_POST',
  FOLLOWED_CHALLENGE = 'FOLLOWED_CHALLENGE',
  FOLLOWED_IDEA = 'FOLLOWED_IDEA',
  FOLLOWED_USER = 'FOLLOWED_USER',
  USER_TAGGED_IN_COMMENT = 'USER_TAGGED_IN_COMMENT',
  USER_TAGGED_IN_IDEA = 'USER_TAGGED_IN_IDEA',
  USER_WITH_CHALLENGE_ACCESS = 'USER_WITH_CHALLENGE_ACCESS'
}

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  slackforpostcomment?: Maybe<Scalars['Boolean']['output']>;
  slackforpostfollow?: Maybe<Scalars['Boolean']['output']>;
  slackforpostvote?: Maybe<Scalars['Boolean']['output']>;
  teamsConversationId?: Maybe<Scalars['String']['output']>;
  teamsforpostcomment?: Maybe<Scalars['Boolean']['output']>;
  teamsforpostfollow?: Maybe<Scalars['Boolean']['output']>;
  teamsforpostvote?: Maybe<Scalars['Boolean']['output']>;
};

export enum NotificationSettingsEventAction {
  AWARD = 'AWARD',
  COMMENT = 'COMMENT',
  FOLLOW = 'FOLLOW',
  MOVE = 'MOVE',
  NEW = 'NEW',
  REJECT = 'REJECT',
  REMOVE = 'REMOVE',
  REPLY = 'REPLY',
  TAG = 'TAG',
  VOTE = 'VOTE'
}

export enum NotificationSettingsEventSubject {
  CHALLENGE = 'CHALLENGE',
  COMMENT = 'COMMENT',
  IDEA = 'IDEA',
  MESSAGE = 'MESSAGE',
  USER = 'USER'
}

export enum NotificationSettingsFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY'
}

export enum NotificationSettingsTransport {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  SLACK = 'SLACK',
  STREAM = 'STREAM'
}

export enum NotificationType {
  BLOG_POST_COMMENT = 'BLOG_POST_COMMENT',
  BLOG_POST_NEW = 'BLOG_POST_NEW',
  CHALLENGE_CHECK_OUT = 'CHALLENGE_CHECK_OUT',
  CHALLENGE_ENTERED_PHASE = 'CHALLENGE_ENTERED_PHASE',
  CHALLENGE_NEW = 'CHALLENGE_NEW',
  COMMENT_NEW = 'COMMENT_NEW',
  COMMENT_REPLY = 'COMMENT_REPLY',
  IDEA_CHECK_OUT = 'IDEA_CHECK_OUT',
  IDEA_COMMENT = 'IDEA_COMMENT',
  IDEA_FOLLOW = 'IDEA_FOLLOW',
  IDEA_MILESTONE_REACHED = 'IDEA_MILESTONE_REACHED',
  IDEA_NEW = 'IDEA_NEW',
  IDEA_PHASE_REACHED = 'IDEA_PHASE_REACHED',
  IDEA_TAG = 'IDEA_TAG',
  IDEA_VOTE = 'IDEA_VOTE'
}

export type Phase = {
  __typename?: 'Phase';
  access: AccessOption;
  challenge?: Maybe<Challenge>;
  challengeId?: Maybe<Scalars['ID']['output']>;
  commentMode?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  ideas?: Maybe<Array<Idea>>;
  isPrivateSubmission?: Maybe<Scalars['Boolean']['output']>;
  moderatorRights?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  participantRights?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<ChallengeQuestion>>;
  scorecards?: Maybe<Array<Scorecard>>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  stats?: Maybe<Scalars['String']['output']>;
  translations?: Maybe<Array<Translation>>;
  visible?: Maybe<Scalars['Int']['output']>;
  voteMode?: Maybe<Scalars['Int']['output']>;
  voting: Voting;
};


export type PhaseIdeasArgs = {
  filters?: InputMaybe<UserSearchFilters>;
};

export type PhaseInput = {
  access: AccessOptionInput;
  description: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['StringLength_Max250']['input']>;
  questions?: InputMaybe<Array<ChallengeQuestionInput>>;
  startDate: Scalars['String']['input'];
  translations?: InputMaybe<Array<TranslationInput>>;
  voting?: InputMaybe<VotingInput>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  accessibleChallenges?: Maybe<Array<Challenge>>;
  apiRequests?: Maybe<Array<ApiRequest>>;
  badge?: Maybe<Badge>;
  blogPost?: Maybe<BlogPost>;
  blogPosts: Array<BlogPost>;
  challenge?: Maybe<Challenge>;
  challengeExist: Scalars['Boolean']['output'];
  challenges?: Maybe<Array<Challenge>>;
  checkFollowing: SuccessMessageResponseType;
  coauthorIdeas: Array<Scalars['Int']['output']>;
  community?: Maybe<Community>;
  dailySummary?: Maybe<Array<StatResultType>>;
  filteredUsers: Array<Scalars['Int']['output']>;
  getScorecards: Array<Challenge>;
  globalScoreResets?: Maybe<Array<ScoreReset>>;
  globalTemplates?: Maybe<Array<GlobalTemplate>>;
  idea?: Maybe<Idea>;
  ideaStatusesSummary?: Maybe<Array<IdeaStatusesResultType>>;
  ideaTagsSummary?: Maybe<Array<IdeaTagsResultType>>;
  ideasAndCount: IdeasAndCount;
  me?: Maybe<User>;
  moderatorActionLogs?: Maybe<ModeratorActionResult>;
  notifications: Array<NotificationEvent>;
  phase?: Maybe<Phase>;
  searchUsergroups: Array<UserGroup>;
  searchUsers: Array<User>;
  socialMedia?: Maybe<Array<SocialMedia>>;
  stat?: Maybe<Stat>;
  stats?: Maybe<Array<StatResultType>>;
  statusIdeasAndCount?: Maybe<IdeasAndCount>;
  tagIdeasAndCount?: Maybe<IdeasAndCount>;
  template?: Maybe<Template>;
  templates?: Maybe<Templates>;
  theme?: Maybe<Theme>;
  themeIdeasAndCount: IdeasAndCount;
  themeIdeasSummary?: Maybe<Array<ThemeIdeasResultType>>;
  themes: Array<Theme>;
  user?: Maybe<User>;
  userFields: Array<UserField>;
  userLoginStats?: Maybe<Array<StatResultType>>;
  userNotificationSettings: Array<UserNotificationSettings>;
  userRankings?: Maybe<Array<UserRanking>>;
  userScoreResets?: Maybe<Array<ScoreReset>>;
  users?: Maybe<UserSearchResult>;
  votesAndCommentsCount: VoteAndCommentResultType;
};


export type QueryAccessibleChallengesArgs = {
  publicationStatus?: InputMaybe<ChallengePublicationStatus>;
};


export type QueryBadgeArgs = {
  id: Scalars['String']['input'];
};


export type QueryBlogPostArgs = {
  id: Scalars['Int']['input'];
};


export type QueryBlogPostsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryChallengeArgs = {
  id: Scalars['String']['input'];
};


export type QueryChallengeExistArgs = {
  id: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type QueryChallengesArgs = {
  order?: InputMaybe<Array<Scalars['String']['input']>>;
  publicationStatus?: InputMaybe<ChallengePublicationStatus>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryCheckFollowingArgs = {
  targetUser: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryCoauthorIdeasArgs = {
  customFiltersUserIds: Array<Scalars['Int']['input']>;
  filters: UserSearchFilters;
};


export type QueryCommunityArgs = {
  id: Scalars['String']['input'];
};


export type QueryDailySummaryArgs = {
  challengeSelection?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserSearchFilters>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFilteredUsersArgs = {
  filters: UserSearchFilters;
};


export type QueryGetScorecardsArgs = {
  id: Scalars['String']['input'];
};


export type QueryIdeaArgs = {
  id: Scalars['Int']['input'];
};


export type QueryIdeaStatusesSummaryArgs = {
  challengeSelection?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserSearchFilters>;
};


export type QueryIdeaTagsSummaryArgs = {
  challengeSelection?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserSearchFilters>;
};


export type QueryIdeasAndCountArgs = {
  challengeSelection?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserSearchFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  milestoneSelection?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  phaseSelection?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryModeratorActionLogsArgs = {
  ideaId: Scalars['Int']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNotificationsArgs = {
  categories?: InputMaybe<Array<NotificationCategory>>;
  from?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPhaseArgs = {
  id: Scalars['String']['input'];
};


export type QuerySearchUsergroupsArgs = {
  searchText: Scalars['String']['input'];
  types: Array<UserGroupType>;
};


export type QuerySearchUsersArgs = {
  searchText: Scalars['String']['input'];
};


export type QueryStatArgs = {
  id: Scalars['String']['input'];
};


export type QueryStatsArgs = {
  endDate: Scalars['String']['input'];
  filters?: InputMaybe<UserSearchFilters>;
  startDate: Scalars['String']['input'];
  type: Array<Scalars['Int']['input']>;
};


export type QueryStatusIdeasAndCountArgs = {
  challengeSelection: Scalars['String']['input'];
  filters?: InputMaybe<UserSearchFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  statusSelection: Scalars['String']['input'];
};


export type QueryTagIdeasAndCountArgs = {
  challengeSelection: Scalars['String']['input'];
  filters?: InputMaybe<UserSearchFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  tagSelection: Scalars['String']['input'];
};


export type QueryTemplateArgs = {
  id: Scalars['Float']['input'];
};


export type QueryThemeArgs = {
  id: Scalars['Float']['input'];
};


export type QueryThemeIdeasAndCountArgs = {
  challengeSelection?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserSearchFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  themeSelection?: InputMaybe<Scalars['String']['input']>;
};


export type QueryThemeIdeasSummaryArgs = {
  challengeSelection?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserSearchFilters>;
  themeSelection?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserLoginStatsArgs = {
  endDate: Scalars['String']['input'];
  filters?: InputMaybe<UserSearchFilters>;
  startDate: Scalars['String']['input'];
};


export type QueryUserRankingsArgs = {
  challengeId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserScoreResetsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  filters?: InputMaybe<UserSearchFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVotesAndCommentsCountArgs = {
  filters?: InputMaybe<UserSearchFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ColumnSortType>;
  publicationStatus?: InputMaybe<ChallengePublicationStatus>;
};

export type ScoreLog = {
  __typename?: 'ScoreLog';
  id: Scalars['String']['output'];
  isRemoved: Scalars['Boolean']['output'];
};

export type ScoreReset = {
  __typename?: 'ScoreReset';
  date: Scalars['DateTime']['output'];
  excludeBadges: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  type: ScoreResetType;
};

export enum ScoreResetType {
  GLOBAL = 'GLOBAL',
  USER = 'USER'
}

export type Scorecard = {
  __typename?: 'Scorecard';
  challengeId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  milestoneId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  phaseId?: Maybe<Scalars['ID']['output']>;
  translations?: Maybe<Array<Translation>>;
};

export type ScorecardInput = {
  challengeId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['StringLength_Max250']['input']>;
  id: Scalars['String']['input'];
  milestoneId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['StringLength_Max250']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  phaseId?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Array<TranslationInput>>;
};

export type ScorecardLabel = {
  __typename?: 'ScorecardLabel';
  challengeId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  milestoneId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phaseId?: Maybe<Scalars['ID']['output']>;
  rating?: Maybe<Scalars['String']['output']>;
  translations?: Maybe<Array<Translation>>;
};

export type ScorecardLabelInput = {
  challengeId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  milestoneId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['StringLength_Max250']['input']>;
  rating?: InputMaybe<Scalars['StringLength_Max11']['input']>;
  translations?: InputMaybe<Array<TranslationInput>>;
};

export type ScorecardsAndLabels = {
  __typename?: 'ScorecardsAndLabels';
  cards?: Maybe<Array<Scorecard>>;
  labels?: Maybe<Array<ScorecardLabel>>;
};

export type ScorecardsAndLabelsInput = {
  cards?: InputMaybe<Array<ScorecardInput>>;
  labels?: InputMaybe<Array<ScorecardLabelInput>>;
};

export type SocialLinkInput = {
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
  url?: InputMaybe<Scalars['StringLength_Max250_Min6']['input']>;
};

export type SocialLinkType = {
  __typename?: 'SocialLinkType';
  id: Scalars['String']['output'];
  type: SocialMediaType;
  url: Scalars['String']['output'];
};

export type SocialMedia = {
  __typename?: 'SocialMedia';
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: SocialMediaType;
  urlProfilePattern: Scalars['String']['output'];
};

export enum SocialMediaType {
  Facebook = 'Facebook',
  Google = 'Google',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Unknown = 'Unknown',
  X = 'X'
}

export type Stat = {
  __typename?: 'Stat';
  date?: Maybe<Scalars['String']['output']>;
  hubbub?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sourceId?: Maybe<Scalars['String']['output']>;
  type: StatType;
  url?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Scalars['String']['output']>;
  value1?: Maybe<Scalars['String']['output']>;
  value2?: Maybe<Scalars['String']['output']>;
};

export type StatResultType = {
  __typename?: 'StatResultType';
  created?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  total: Scalars['Float']['output'];
  type?: Maybe<Scalars['Float']['output']>;
};

export enum StatType {
  COMMENT_CREATED = 'COMMENT_CREATED',
  IDEA_CREATED = 'IDEA_CREATED',
  PAGE_VIEW = 'PAGE_VIEW',
  POST_VIEW = 'POST_VIEW',
  USER_ACTIVATION = 'USER_ACTIVATION',
  USER_JOIN = 'USER_JOIN',
  VOTE = 'VOTE'
}

export type SuccessMessageResponseType = {
  __typename?: 'SuccessMessageResponseType';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  communityId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TagLinkPost = {
  __typename?: 'TagLinkPost';
  id: Scalars['ID']['output'];
  itemId: Scalars['Int']['output'];
  itemType: Scalars['String']['output'];
  tagId: Scalars['Int']['output'];
  tagType: Scalars['String']['output'];
};

export type Template = {
  __typename?: 'Template';
  communityId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  template: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type Templates = {
  __typename?: 'Templates';
  community: Array<Template>;
  global: Array<GlobalTemplate>;
};

export type Theme = {
  __typename?: 'Theme';
  archived?: Maybe<Scalars['Int']['output']>;
  challenge?: Maybe<Challenge>;
  challengeId?: Maybe<Scalars['Int']['output']>;
  communityId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ideas?: Maybe<Array<Idea>>;
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  phaseStats?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  walletLastUpdate?: Maybe<Scalars['DateTime']['output']>;
};

export type ThemeIdeasResultType = {
  __typename?: 'ThemeIdeasResultType';
  ideaCount: Scalars['Float']['output'];
  themeId: Scalars['Float']['output'];
  themeName: Scalars['String']['output'];
};

export type ThemeInput = {
  archived: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['StringLength_Max140']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['StringLength_Max250_Min1']['input']>;
  order: Scalars['Int']['input'];
};

export type Translation = {
  __typename?: 'Translation';
  communityId: Scalars['ID']['output'];
  field: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  iso: Scalars['String']['output'];
  objectId?: Maybe<Scalars['ID']['output']>;
  objectType: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TranslationInput = {
  field: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  iso: Scalars['String']['input'];
  objectId: Scalars['String']['input'];
  objectType: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  accounts?: Maybe<Array<UserAccount>>;
  badges?: Maybe<Array<BadgeWithCountType>>;
  bio?: Maybe<Scalars['String']['output']>;
  commentsCount?: Maybe<Scalars['Int']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  emailforcommentreply?: Maybe<Scalars['Boolean']['output']>;
  emailforcommentvote?: Maybe<Scalars['Boolean']['output']>;
  emailforpostcomment?: Maybe<Scalars['Boolean']['output']>;
  emailforpostfollow?: Maybe<Scalars['Boolean']['output']>;
  emailforpostvote?: Maybe<Scalars['Boolean']['output']>;
  emailforuserfollow?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  followers?: Maybe<Array<User>>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  following?: Maybe<Array<User>>;
  followingCount?: Maybe<Scalars['Int']['output']>;
  hasPassword?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  ideas?: Maybe<Array<Idea>>;
  ideasCount?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  notificationSettings?: Maybe<NotificationSettings>;
  passwordChangedAt?: Maybe<Scalars['DateTime']['output']>;
  profileImageFile?: Maybe<File>;
  profileImageId?: Maybe<Scalars['ID']['output']>;
  ranking?: Maybe<UserRanking>;
  regCode?: Maybe<Scalars['String']['output']>;
  screenName?: Maybe<Scalars['String']['output']>;
  socialLinks?: Maybe<Array<SocialLinkType>>;
  subscribedChallengeIds?: Maybe<Array<Scalars['String']['output']>>;
  subscribedIdeaIds?: Maybe<Array<Scalars['String']['output']>>;
  timezone: Scalars['String']['output'];
  town?: Maybe<Scalars['String']['output']>;
  userAccess?: Maybe<UserAccess>;
  userFields?: Maybe<Array<UserFieldType>>;
  useroids?: Maybe<Array<Useroid>>;
  votesCount?: Maybe<Scalars['Int']['output']>;
};

export type UserAccess = {
  __typename?: 'UserAccess';
  awaitingAcceptance?: Maybe<Scalars['Int']['output']>;
  communityId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instanceJoined: Scalars['DateTime']['output'];
  language?: Maybe<Scalars['String']['output']>;
  receiveEmails?: Maybe<Scalars['Boolean']['output']>;
  stealthMode?: Maybe<Scalars['Boolean']['output']>;
  userId: Scalars['Int']['output'];
  userType: Scalars['Int']['output'];
};

export type UserAccount = {
  __typename?: 'UserAccount';
  id: Scalars['ID']['output'];
  provider: AccountProviderType;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  userName: Scalars['String']['output'];
};

export type UserBadge = {
  __typename?: 'UserBadge';
  badgeId: Scalars['ID']['output'];
  byUser?: Maybe<Scalars['ID']['output']>;
  communityId: Scalars['ID']['output'];
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId: Scalars['ID']['output'];
};

export type UserField = {
  __typename?: 'UserField';
  communityId?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Array<Scalars['String']['output']>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type UserFieldData = {
  __typename?: 'UserFieldData';
  communityId: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  userFieldId: Scalars['Float']['output'];
  userId: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type UserFieldType = {
  __typename?: 'UserFieldType';
  hidden: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type UserFollow = {
  __typename?: 'UserFollow';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  targetUser: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UserGroup = {
  __typename?: 'UserGroup';
  challengeCreator?: Maybe<Scalars['Boolean']['output']>;
  communityId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastIndexed?: Maybe<Scalars['Int']['output']>;
  loginChoice?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  system?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<UserGroupType>;
  userFieldId?: Maybe<Scalars['Int']['output']>;
  userFieldOption?: Maybe<Scalars['String']['output']>;
};

export type UserGroupAccess = {
  __typename?: 'UserGroupAccess';
  id: Scalars['Int']['output'];
  userGroupId: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export enum UserGroupType {
  AZURE_LOGIN = 'AZURE_LOGIN',
  CROWDICITY_LOGIN = 'CROWDICITY_LOGIN',
  DYNAMIC = 'DYNAMIC',
  MEC_LOGIN = 'MEC_LOGIN',
  SAML_LOGIN = 'SAML_LOGIN',
  SOCIAL_LOGIN = 'SOCIAL_LOGIN',
  STATIC = 'STATIC'
}

export type UserGroupWithModerationStatus = {
  __typename?: 'UserGroupWithModerationStatus';
  canModerate: Scalars['Boolean']['output'];
  usergroup: UserGroup;
};

export type UserNotificationSettings = {
  __typename?: 'UserNotificationSettings';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  eventAction?: Maybe<NotificationSettingsEventAction>;
  eventSubject?: Maybe<NotificationSettingsEventSubject>;
  frequency?: Maybe<NotificationSettingsFrequency>;
  id: Scalars['ID']['output'];
  transport?: Maybe<NotificationSettingsTransport>;
};

export type UserNotificationSettingsInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};

export type UserPost = {
  __typename?: 'UserPost';
  addedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  ideaId?: Maybe<Scalars['ID']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type UserRanking = {
  __typename?: 'UserRanking';
  calculateDate?: Maybe<Scalars['DateTime']['output']>;
  challengeId?: Maybe<Scalars['ID']['output']>;
  communityId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  score: Scalars['Int']['output'];
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
};

export type UserSearchFilters = {
  country?: InputMaybe<Array<Scalars['String']['input']>>;
  initial?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  userFieldType?: InputMaybe<Scalars['String']['input']>;
  userFields?: InputMaybe<Scalars['String']['input']>;
};

export type UserSearchResult = {
  __typename?: 'UserSearchResult';
  userBatch?: Maybe<Array<User>>;
  userCount?: Maybe<Scalars['Int']['output']>;
};

export type UserSession = {
  __typename?: 'UserSession';
  email?: Maybe<Scalars['String']['output']>;
  expiry: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  pkey: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type UserSettingsResponseType = {
  __typename?: 'UserSettingsResponseType';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  userSettings?: Maybe<Array<UserNotificationSettings>>;
};

export type UserType = {
  __typename?: 'UserType';
  canModerate: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UserWithModerationStatus = {
  __typename?: 'UserWithModerationStatus';
  canModerate: Scalars['Boolean']['output'];
  user: User;
};

export type Useroid = {
  __typename?: 'Useroid';
  id: Scalars['ID']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type UsersAndGroups = {
  __typename?: 'UsersAndGroups';
  groups?: Maybe<Array<UserGroup>>;
  users?: Maybe<Array<User>>;
};

export type Vote = {
  __typename?: 'Vote';
  average?: Maybe<Scalars['Float']['output']>;
  date: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  milestoneId?: Maybe<Scalars['ID']['output']>;
  parentId: Scalars['ID']['output'];
  phase?: Maybe<Scalars['String']['output']>;
  scorecardId?: Maybe<Scalars['ID']['output']>;
  type: VotingType;
  userId: Scalars['ID']['output'];
};

export type VoteAndCommentResultItemType = {
  __typename?: 'VoteAndCommentResultItemType';
  challengeCreatedAt: Scalars['DateTime']['output'];
  challengeId: Scalars['Int']['output'];
  challengeTitle: Scalars['String']['output'];
  commentCount: Scalars['Int']['output'];
  voteCount: Scalars['Int']['output'];
};

export type VoteAndCommentResultType = {
  __typename?: 'VoteAndCommentResultType';
  count: Scalars['Int']['output'];
  rows: Array<VoteAndCommentResultItemType>;
};

export type VoterInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  milestoneId: Scalars['String']['input'];
  type: Scalars['String']['input'];
  voterId: Scalars['String']['input'];
};

export type Voting = {
  __typename?: 'Voting';
  isBlind?: Maybe<Scalars['Boolean']['output']>;
  options?: Maybe<VotingOptions>;
  scorecards?: Maybe<ScorecardsAndLabels>;
  showVoters?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<VotingType>;
  wallet?: Maybe<VotingWallet>;
};

export type VotingInput = {
  isBlind?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<VotingOptionsInput>;
  scorecards?: InputMaybe<ScorecardsAndLabelsInput>;
  showVoters?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<VotingType>;
  wallet?: InputMaybe<VotingWalletInput>;
};

export type VotingOptions = {
  __typename?: 'VotingOptions';
  maxScore?: Maybe<Scalars['Int']['output']>;
  maxStars?: Maybe<Scalars['Int']['output']>;
  minimumRatings?: Maybe<Scalars['Int']['output']>;
};

export type VotingOptionsInput = {
  maxScore?: InputMaybe<Scalars['Int']['input']>;
  maxStars?: InputMaybe<Scalars['Int']['input']>;
  minimumRatings?: InputMaybe<Scalars['Int']['input']>;
};

export enum VotingType {
  POINTSAWARD = 'POINTSAWARD',
  SCORECARD = 'SCORECARD',
  STARS = 'STARS',
  UP = 'UP',
  UPDOWN = 'UPDOWN'
}

export type VotingWallet = {
  __typename?: 'VotingWallet';
  allowance?: Maybe<Scalars['Int']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type VotingWalletInput = {
  allowance?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};
