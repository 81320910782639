import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, ErrorMessage, LoadingIndicator, Select } from '@m/alchemy-ui';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useIntl } from 'react-intl';
import Exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import {
  type ChallengesQuery,
  type CoauthorIdeasQuery,
  type FilteredUsersQuery,
  GET_CHALLENGES,
  GET_COAUTHOR_IDEAS,
  GET_COUNTRIES,
  GET_STATUS_IDEAS_AND_COUNT,
  GET_USERFIELDS_FILTERED_USERIDS,
  type GetCountriesQuery,
  type GetIdeaStatusesSummaryQuery,
  type GetStatusIdeasAndCountQuery
} from '../../graphql/queries';

import { useUserFields } from '../../hooks';
import { CommunityContext } from '../../context';
import { GET_IDEA_STATUSES_SUMMARY } from '../../graphql/queries/stat';
import { getExportIdeasUrl } from '../../helpers';
import { Grid, GridCol, GridHeader, GridRow, Pagination } from './Analytics.styled';

import * as Styled from './components/SearchFilters.styled';
import { downloadIcon, ideasByStatusChartOptions } from './HighchartsData';
import { CustomFilters } from './CustomFilters';
import { type ChallengeListItemType, type IdeaStatusSummaryItemType, type UserFieldType } from './types';
import { IdeasExportButton, IdeasTable } from './components';

import { CHART_MAX_COLUMN_COUNT, IDEAS_DEFAULT_OFFSET, IDEAS_DEFAULT_SORT, IDEAS_PER_PAGE } from './constants';

import { AlchemySortDirectionType, ChallengeSelectionType, CoreApiSortDirectionType } from './enums';

// Enable data export for chart
Exporting(Highcharts);
// Enable display of no data for chart
NoDataToDisplay(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.download = downloadIcon;

export const IdeasByStatusStats = () => {
  const intl = useIntl();

  const community = useContext(CommunityContext);

  const { data: communityUserFields } = useUserFields();

  const defaultUserFieldOptions: UserFieldType = {};
  const [userFieldOptions, setUserFieldOptions] = useState(defaultUserFieldOptions);
  const [countries, setCountries] = useState<string[]>([]);

  const [challenge, setChallenge] = useState<string>(ChallengeSelectionType.PUBLISHED);
  const [status, setStatus] = useState<string | undefined>();

  const defaultChartOptions = useMemo(() => ideasByStatusChartOptions(intl), [intl]);

  const { data: countryList } = useQuery<GetCountriesQuery>(GET_COUNTRIES, {
    variables: { id: community.id },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    countryList?.community && setCountries(countryList.community?.countries as string[]);
  }, [countryList]);

  const { loading: loadingChallenges, data: challengeList } = useQuery<ChallengesQuery>(GET_CHALLENGES, {
    variables: { order: ['title'] },
    fetchPolicy: 'cache-first'
  });

  const { data: customFiltersUserIds } = useQuery<FilteredUsersQuery>(GET_USERFIELDS_FILTERED_USERIDS, {
    variables: { filters: { userFields: JSON.stringify(userFieldOptions) } },
    fetchPolicy: 'cache-first'
  });

  const { data: coauthorIdeaIds } = useQuery<CoauthorIdeasQuery>(GET_COAUTHOR_IDEAS, {
    variables: {
      customFiltersUserIds: customFiltersUserIds?.filteredUsers || [],
      filters: { userFields: JSON.stringify(userFieldOptions) }
    },
    // There is no need to request coauthors data in case custom fields are not selected
    skip: !customFiltersUserIds
  });

  const [ideaStatusesSummaryQueryVariables, setIdeaStatusesSummaryQueryVariables] = useState({
    challengeSelection: challenge,
    filters: { userFields: JSON.stringify(userFieldOptions) }
  });

  const { data: chartData, loading: loadingChart } = useQuery<GetIdeaStatusesSummaryQuery>(GET_IDEA_STATUSES_SUMMARY, {
    variables: ideaStatusesSummaryQueryVariables
  });

  const [statusIdeasQueryVariables, setStatusIdeasQueryVariables] = useState({
    challengeSelection: challenge,
    statusSelection: status,
    filters: { userFields: JSON.stringify(userFieldOptions) },
    order: [IDEAS_DEFAULT_SORT],
    offset: IDEAS_DEFAULT_OFFSET
  });

  const isStatusSelected = useMemo(
    () => statusIdeasQueryVariables.statusSelection !== undefined,
    [statusIdeasQueryVariables]
  );

  const { data: ideasData, loading: loadingIdeas } = useQuery<GetStatusIdeasAndCountQuery>(GET_STATUS_IDEAS_AND_COUNT, {
    variables: statusIdeasQueryVariables,
    // If a specific status is not selected, do not request any ideas data
    skip: !isStatusSelected
  });

  useEffect(() => {
    setStatusIdeasQueryVariables({
      challengeSelection: challenge,
      statusSelection: status,
      filters: { userFields: JSON.stringify(userFieldOptions) },
      order: [IDEAS_DEFAULT_SORT],
      offset: IDEAS_DEFAULT_OFFSET
    });
  }, [challenge, setStatusIdeasQueryVariables, status, userFieldOptions]);

  const sortIdeas = (column: string) => {
    const { order } = statusIdeasQueryVariables;
    const [sortColumn, sortDirection] = order[0];
    const newOrder = [
      [
        column,
        column === sortColumn && sortDirection === CoreApiSortDirectionType.ASC
          ? CoreApiSortDirectionType.DESC
          : CoreApiSortDirectionType.ASC
      ]
    ];

    setStatusIdeasQueryVariables({
      ...statusIdeasQueryVariables,
      order: newOrder
    });
  };

  const getColumnSortDirection = (column: string) => {
    const { order } = statusIdeasQueryVariables;
    const [sortColumn, sortDirection] = order[0];
    return sortColumn === column
      ? sortDirection === CoreApiSortDirectionType.DESC
        ? AlchemySortDirectionType.DESC
        : AlchemySortDirectionType.ASC
      : 'none';
  };

  const chartOptions = useMemo(() => {
    const data = chartData?.ideaStatusesSummary ? chartData.ideaStatusesSummary : [];

    return {
      ...defaultChartOptions,
      xAxis: [
        {
          ...defaultChartOptions.xAxis[0],
          ...(data.length >= CHART_MAX_COLUMN_COUNT && {
            max: CHART_MAX_COLUMN_COUNT,
            scrollbar: {
              enabled: true
            }
          }),
          categories: data ? data.map(({ statusName }: IdeaStatusSummaryItemType) => statusName) : []
        },
        {
          ...defaultChartOptions.xAxis[1],
          categories: data ? data.map(({ statusId }: IdeaStatusSummaryItemType) => statusId) : []
        }
      ],
      series: [
        {
          ...defaultChartOptions.series[0],
          data: data ? data.map(({ ideaCount }: IdeaStatusSummaryItemType) => ideaCount) : []
        }
      ],
      plotOptions: {
        ...defaultChartOptions.plotOptions,
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: (event: Highcharts.PointClickEventObject) => {
                const statusIds = event.point.series.chart.xAxis[1].categories;
                const currentStatusIndex = event.point.x;
                const currentStatusId = String(statusIds[currentStatusIndex]);

                setStatus(currentStatusId);
              }
            }
          }
        }
      }
    };
  }, [chartData, defaultChartOptions, setStatus]);

  if ((loadingChallenges && !challengeList) || (loadingChart && !chartData)) {
    return (
      <Styled.LoadingContainer>
        <LoadingIndicator inline />
      </Styled.LoadingContainer>
    );
  }

  return (
    <Grid key="ideasByStatusStats">
      <CustomFilters
        userFieldOptions={userFieldOptions}
        communityUserFields={communityUserFields}
        countries={countries}
        userFieldUpdate={(userField: UserFieldType) => {
          // Reset selected status - means "no selected idea status"
          setStatus(undefined);
          setUserFieldOptions(userField);
        }}
      />
      <GridHeader key="challengeSelectionHeader">
        {intl.formatMessage({ id: 'show', defaultMessage: 'Show' })}
      </GridHeader>
      <GridRow key="challengeSelection">
        <Select
          defaultValue={challenge}
          options={[
            {
              label: intl.formatMessage({ id: 'allPublishedChallenges', defaultMessage: 'All Published Challenges' }),
              value: ChallengeSelectionType.PUBLISHED,
              'data-testid': `challenge-${ChallengeSelectionType.PUBLISHED}`
            },
            {
              label: intl.formatMessage({ id: 'allChallenges', defaultMessage: 'All Challenges' }),
              value: ChallengeSelectionType.ALL,
              'data-testid': `challenge-${ChallengeSelectionType.ALL}`
            },
            ...((challengeList as ChallengesQuery) &&
              (challengeList?.challenges as ChallengeListItemType[]) &&
              (challengeList?.challenges as ChallengeListItemType[]).map((challenge: ChallengeListItemType) => ({
                label: challenge.title,
                value: challenge.id,
                'data-testid': `challenge-${challenge.title}`
              })))
          ]}
          onChange={(e) => {
            setChallenge(e.value);
            // And additionally reset a previously selected status
            setStatus(undefined);
          }}
        />
      </GridRow>
      <GridRow key="updateButton">
        <Button
          priority="secondary"
          onClick={() => {
            setIdeaStatusesSummaryQueryVariables({
              challengeSelection: challenge,
              filters: { userFields: JSON.stringify(userFieldOptions) }
            });
          }}
        >
          {intl.formatMessage({ id: 'update', defaultMessage: 'Update' })}
        </Button>
      </GridRow>
      <GridRow key="chart">
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </GridRow>
      {isStatusSelected && (
        <GridRow key="table" justify="center">
          {loadingIdeas && !ideasData ? (
            <Styled.LoadingContainer>
              <LoadingIndicator inline />
            </Styled.LoadingContainer>
          ) : ideasData?.statusIdeasAndCount && ideasData.statusIdeasAndCount.count > 0 ? (
            <>
              <GridCol>
                <IdeasTable
                  getColumnSortDirection={getColumnSortDirection}
                  ideasAndCount={ideasData.statusIdeasAndCount}
                  intl={intl}
                  sortIdeas={sortIdeas}
                />
              </GridCol>
              <GridCol>
                {/* Even if there is only 1 page, we should keep the pagination */}
                <Pagination
                  numPages={Math.ceil(ideasData.statusIdeasAndCount.count / IDEAS_PER_PAGE)}
                  activeIndex={Math.ceil(statusIdeasQueryVariables.offset / IDEAS_PER_PAGE)}
                  onPageClick={(page) => {
                    const pageIndex = page.index as number;
                    setStatusIdeasQueryVariables({
                      ...statusIdeasQueryVariables,
                      offset: pageIndex * IDEAS_PER_PAGE
                    });
                  }}
                />
              </GridCol>
            </>
          ) : (
            <ErrorMessage
              message={intl.formatMessage({ id: 'noIdeasWereFound', defaultMessage: 'No ideas were found' })}
              details={intl.formatMessage({
                id: 'pleaseAdjustYourSearch',
                defaultMessage: 'Please adjust your search parameters and try again.'
              })}
            />
          )}
        </GridRow>
      )}
      {isStatusSelected && ideasData?.statusIdeasAndCount && ideasData.statusIdeasAndCount.count > 0 && (
        <GridRow key="exportData">
          <IdeasExportButton
            exportUrl={getExportIdeasUrl({ challenge, status, customFiltersUserIds, coauthorIdeaIds }, community)}
            intl={intl}
          />
        </GridRow>
      )}
    </Grid>
  );
};
