import { SubscribeSingleButtonStyle } from './SubscribeSingleButton.styled';

interface SubscribeSingleButtonProps {
  readonly buttonColor: string;
  readonly buttonCaption: string;
  readonly buttonToolTip: string;
  readonly isSingleMode?: boolean;
  readonly onClick: (args: []) => void;
}

export const SubscribeSingleButton = ({
  buttonColor,
  buttonCaption,
  buttonToolTip,
  isSingleMode = true,
  onClick
}: SubscribeSingleButtonProps) => (
  <SubscribeSingleButtonStyle
    color={`#${buttonColor}`}
    singleMode={isSingleMode}
    onClick={() => onClick([])}
    data-tip={buttonToolTip}
    data-testid="subscribe-single-button"
  >
    {buttonCaption}
  </SubscribeSingleButtonStyle>
);
