import { MenuList, type MenuListItemProps, PopperBox } from '@m/alchemy-ui';
import { FormattedMessage } from 'react-intl';
import { SubscribeSingleButton } from '../SubscribeSingleButton/SubscribeSingleButton';
import {
  ButtonContainer,
  ButtonWide,
  Container,
  CustomMenuFooter,
  SubscribeMenuButton
} from './SubscribeMultiSelect.styled';

interface SubscribeMultiSelectProps {
  selectedAsString: string;
  items: MenuListItemProps[];
  colors: { button: string };
  selected: string[];
  buttonToolTip: string;
  onSubscribeApply: (selected: string[]) => void;
  onCancel: () => void;
}

export const SubscribeMultiSelect = ({
  selectedAsString,
  items,
  colors,
  selected,
  buttonToolTip,
  onCancel,
  onSubscribeApply
}: SubscribeMultiSelectProps) => (
  <Container>
    <SubscribeSingleButton
      isSingleMode={false}
      onClick={() => {}}
      buttonColor={colors.button}
      buttonCaption={selectedAsString}
      buttonToolTip={buttonToolTip}
    />
    <SubscribeMenuButton
      data-testid="subscribe-multi-select-button"
      color={`#${colors.button}`}
      overlay={({ closeAndReturnFocus }) => (
        <PopperBox hasArrow hasPadding={false} maxWidth={260}>
          <MenuList aria-label="Actions" mode="multiselect-with-checkboxes" items={items}>
            <CustomMenuFooter>
              <ButtonContainer>
                <ButtonWide
                  priority="secondary"
                  onClick={() => {
                    onCancel();
                    closeAndReturnFocus();
                  }}
                >
                  <FormattedMessage id="cancel" defaultMessage="Cancel" />
                </ButtonWide>
                <ButtonWide
                  priority="primary"
                  onClick={() => {
                    onSubscribeApply(selected);
                    closeAndReturnFocus();
                  }}
                >
                  <FormattedMessage id="apply" defaultMessage="Apply" />
                </ButtonWide>
              </ButtonContainer>
            </CustomMenuFooter>
          </MenuList>
        </PopperBox>
      )}
    >
      &nbsp;
    </SubscribeMenuButton>
  </Container>
);
